import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { OdFlowsResponse } from '@shared/resources/analysis/od-flows-response';
import { OriginOrDestination } from '@shared/resources/analysis/origin-or-destination';
import { ofType } from '@shared/utils/typing-utils';
import * as echarts from 'echarts';
import { filter, takeUntil } from 'rxjs';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphUtils } from 'src/app/utils/graph-utils';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartService } from '../../services/chart.service';
import { GraphStyle } from '../../utils/constants/graph-style';
import { ChartType } from '../analysis-diagram-bar/chart-type';

interface NodeInfo {
  nodeId: string;
  type: OriginOrDestination;
  label: string;
  isOther: boolean;
  value: number;
  journeys: number;
  persons: number;
  colorIndex: number;
}

@Component({
  selector: 'app-od-flows-chart',
  templateUrl: './od-flows-chart.component.html',
  styleUrl: './od-flows-chart.component.scss'
})
export class OdFlowsChartComponent implements OnInit {

  private static readonly COLOR_PALETTE = Constants.COLOR_CHART_CATEGORIES_XL;
  private static readonly COLOR_OTHER = Constants.COLOR_DAT_MIDGRAY;
  private static readonly THIS_PANEL = ChartType.ORIGINS_AND_DESTINATIONS;

  public readonly HEIGHT_PX = 300;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  private echartsInstance: echarts.ECharts;
  private odFlowsResponse: OdFlowsResponse['flows'] = [];
  private activeToggle: JourneysOrPersons;

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private translateService: TranslateService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => this.fetchOdFlows());

    this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => this.refresh());

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      this.refresh();
    });

    this.chartService.exportClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === OdFlowsChartComponent.THIS_PANEL))
      .subscribe(() => {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      });
    this.chartService.copyClipboardClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === OdFlowsChartComponent.THIS_PANEL))
      .subscribe(() => {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      });
    this.chartService.exportCsvClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === OdFlowsChartComponent.THIS_PANEL))
      .subscribe(() => {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.ORIGINS_AND_DESTINATIONS');
        this.chartService.exportDataToCsv(fileName, this.odFlowsResponse);
      });
    this.chartService.exportOdMatrixClicked
      .pipe(takeUntilDestroyed(), filter(panel => panel === OdFlowsChartComponent.THIS_PANEL))
      .subscribe(() => {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_TITLE.OD_MATRIX');
        const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
        this.analysisHttpService.getOdMatrix(this.analysisId, crossFilterOptions).subscribe(response => {
          this.chartService.exportDataToCsv(fileName, response.flows);
          this.chartService.completeExportOdMatrix();
        });
      });
  }

  public ngOnInit() {
    this.fetchOdFlows();
  }

  private fetchOdFlows() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getOdFlowsDiagram(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(odFlowsResponse => {
        this.odFlowsResponse = odFlowsResponse.flows;
        this.initChartOnlyOnce();
        this.refresh();
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('mousemove', () => this.echartsInstance.getZr().setCursorStyle('default'));
    }
  }

  private refresh() {
    if (this.echartsInstance) {
      this.echartsInstance.setOption(this.getChartOptions());
    }
  }

  private getChartOptions() {
    const nodeMap = new Map<string, NodeInfo>();
    const links = this.odFlowsResponse.map(odFlow => ({
      source: this.generateNode(nodeMap, 'origin', odFlow),
      target: this.generateNode(nodeMap, 'destination', odFlow),
      value: this.activeToggle === JourneysOrPersons.JOURNEYS ? odFlow.journeys : odFlow.persons,
      journeys: odFlow.journeys,
      persons: odFlow.persons
    }));

    const nodes = Array.from(nodeMap.values())
      .sort((a, b) => a.isOther ? 1 : (b.isOther ? -1 : b.value - a.value)) // Largest on top, but OTHER always last.
      .map(node => ({
        name: node.nodeId,
        itemStyle: { color: node.colorIndex === -1 ? OdFlowsChartComponent.COLOR_OTHER : OdFlowsChartComponent.COLOR_PALETTE[node.colorIndex] },
        journeys: node.journeys,
        persons: node.persons,
      }));

    return {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        ...this.getTooltipFormatter(),
        ...GraphStyle.TOOLTIP_STYLE
      },
      series: {
        type: 'sankey',
        layout: 'none',
        layoutIterations: 0,
        draggable: false,
        left: '20%',
        right: '20%',
        top: 0,
        bottom: 5,
        emphasis: {
          focus: 'adjacency'
        },
        levels: [{
          depth: 0,
          label: {
            position: 'left'
          }
        }],
        label: {
          fontSize: 10
        },
        nodes,
        links
      }
    };
  }

  private getTooltipFormatter() {
    const journeysTooltip = this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS');
    const personsTooltip = this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS');
    const totalJourneys = this.odFlowsResponse.map(r => r.journeys).reduce((sum, current) => sum + current, 0);
    const numberFormat = new Intl.NumberFormat(this.translateService.currentLang, { useGrouping: true });

    return {
      formatter: function (params: any) {
        switch (params.dataType) {
          case 'edge': // Tooltip for relation
            return GraphUtils.getTooltipFormatterExpression({
              numberFormat, journeysTooltip, totalJourneys, personsTooltip, excludeColorCircle: true
            }, params);
          case 'node': // Tooltip for origin or destination
            return GraphUtils.getTooltipFormatterExpression({ numberFormat, journeysTooltip, totalJourneys }, params);
          default:
            return '';
        }
      }
    };
  }

  private generateNode(nodeMap: Map<string, NodeInfo>, type: OriginOrDestination, odFlow: OdFlowsResponse['flows'][0]) {
    const labelOrNull = odFlow[type];
    const label = labelOrNull ? labelOrNull : this.translateService.instant('ANALYSIS_OVERVIEW.DIAGRAMS.OD_FLOWS.OTHER') as string;
    const nodeId = `${label}${type === 'origin' ? '\u2009' : '\u2000'}`; // Trick to use same label for origin and destination nodes
    let node = nodeMap.get(nodeId);
    if (!node) {
      node = ofType<NodeInfo>({ nodeId, type, label, isOther: labelOrNull === null, value: 0, journeys: 0, persons: 0, colorIndex: -1 });
      node.colorIndex = this.determineColorIndex(nodeMap, node);
      nodeMap.set(node.nodeId, node);
    }
    node.value += this.activeToggle === JourneysOrPersons.JOURNEYS ? odFlow.journeys : odFlow.persons;
    node.journeys += odFlow.journeys;
    node.persons += odFlow.persons;
    return node.nodeId;
  }

  private determineColorIndex(nodeMap: Map<string, NodeInfo>, newNode: NodeInfo) {
    if (newNode.isOther) {
      return -1;
    }
    if (nodeMap.size === 0) {
      return 0;
    }
    const existingNodeWithSameLabel = Array.from(nodeMap.values()).find(existingNode => existingNode.label === newNode.label);
    if (existingNodeWithSameLabel) {
      return existingNodeWithSameLabel.colorIndex;
    }
    const highestColorIndexSoFar = Math.max(...Array.from(nodeMap.values()).map(node => node.colorIndex));
    return highestColorIndexSoFar + 1;
  }
}
