import { Injectable } from '@angular/core';
import * as ss from 'simple-statistics';

export enum ConfidenceLevel {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export const CONFIDENCE_LEVEL = 0.95;

export const LOW_MARGIN_OF_ERROR_THRESHOLD = 0.1;
export const MEDIUM_MARGIN_OF_ERROR_THRESHOLD = 0.3;

export const CONSERVATIVE_MARGIN_OF_ERROR_FACTOR = 2;

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  public getMarginOfErrorForMaxVariability(sampleSize: number) {
    const maxVariabilityMarginOfError = this.getMarginOfError(sampleSize, 0.5, CONFIDENCE_LEVEL);
    return {
      marginOfErrorPercentage: maxVariabilityMarginOfError * 100,
      confidenceLevel: this.getConfidenceClassifierFromMarginOfError(maxVariabilityMarginOfError)
    };
  }

  public getConservativeMarginOfError(sampleSize: number) {
    const zScore = ss.probit(1 - (1 - CONFIDENCE_LEVEL) / 2);
    const conservativeMarginOfError = CONSERVATIVE_MARGIN_OF_ERROR_FACTOR * zScore / Math.sqrt(sampleSize);
    return {
      marginOfErrorPercentage: conservativeMarginOfError * 100,
      confidenceLevel: this.getConfidenceClassifierFromMarginOfError(conservativeMarginOfError)
    };
  }

  public getMarginOfError(sampleSize: number, proportion: number, confidenceLevel: number) {
    const zScore = ss.probit(1 - (1 - confidenceLevel) / 2);
    return zScore * Math.sqrt(proportion * (1 - proportion)) / Math.sqrt(sampleSize);
  }

  public getConfidenceClassifierFromMarginOfError(marginOfError: number): ConfidenceLevel {
    if (marginOfError < LOW_MARGIN_OF_ERROR_THRESHOLD) {
      return ConfidenceLevel.HIGH;
    } else if (marginOfError < MEDIUM_MARGIN_OF_ERROR_THRESHOLD) {
      return ConfidenceLevel.MEDIUM;
    } else {
      return ConfidenceLevel.LOW;
    }
  }
}
