import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomRegion } from '@shared/resources/geography/custom-region';
import { Municipality } from '@shared/resources/geography/municipality';
import { Province } from '@shared/resources/geography/province';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class GeographyHttpService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getMunicipalities() {
    return this.get<Municipality[]>(`geography/municipalities`);
  }

  public getCustomRegions() {
    return this.get<CustomRegion[]>(`geography/customregions`);
  }

  public getProvinces() {
    return this.get<Province[]>(`geography/provinces`);
  }

  public getCustomRegionCount() {
    return this.get<number>(`geography/customregions?count=true`);
  }

}
