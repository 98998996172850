<mat-button-toggle-group [(ngModel)]="metric" (ngModelChange)="metricChange.emit($event)"
  [hideSingleSelectionIndicator]="true">
  <mat-button-toggle value="COUNT">
    {{ (journeysOrPersons === 'JOURNEYS' ? 'METRICS.COUNT_JOURNEYS' : 'METRICS.COUNT_PERSONS') | translate }}
  </mat-button-toggle>
  <mat-button-toggle value="MINUTES">
    {{ 'METRICS.MINUTES' | translate }}
  </mat-button-toggle>
  <mat-button-toggle value="KILOMETERS">
    {{ 'METRICS.KILOMETERS' | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
