import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ALL_OBJECTIVE_TYPES, ObjectiveType } from '@shared/resources/analysis/objective-type';
import { ObjectivesResponse } from '@shared/resources/analysis/objectives-response';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import * as echarts from 'echarts';
import { takeUntil } from 'rxjs';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphUtils } from 'src/app/utils/graph-utils';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartService } from '../../services/chart.service';
import { GraphStyle } from '../../utils/constants/graph-style';
import { ChartType } from '../analysis-diagram-bar/chart-type';
import { Metric } from '../metric-toggle/metric';

@Component({
  selector: 'app-objectives-chart',
  templateUrl: './objectives-chart.component.html',
  styleUrl: './objectives-chart.component.scss'
})
export class ObjectivesChartComponent implements OnInit {

  private static readonly COLOR_OTHER = Constants.COLOR_DAT_MIDGRAY;
  private static readonly FULL_PALETTE = Object.fromEntries([
    ...ALL_OBJECTIVE_TYPES
      .filter(objectiveType => objectiveType !== 'ONBEKEND')
      .map((objectiveType, index) => [objectiveType, Constants.COLOR_CHART_CATEGORIES_XL[index]]),
    ['ONBEKEND', ObjectivesChartComponent.COLOR_OTHER]
  ]) as { [objectType in ObjectiveType]: string };

  public readonly HEIGHT_PX = 380;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  public metric: Metric = 'COUNT';

  private panelType: ChartType = ChartType.OBJECTIVES;
  private echartsInstance: echarts.ECharts;
  private objectivesResponse: ObjectivesResponse['counts'] = [];
  private activeToggle: JourneysOrPersons;

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(changes => {
      if (changes.every(change => change === 'objectives')) {
        this.refreshChart();
      } else {
        this.fetchJourneysCountPerObjective();
      }
    });

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      this.refreshChart();
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });
    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });
    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.OBJECTIVES');
        this.chartService.exportDataToCsv(fileName, this.objectivesResponse);
      }
    });
  }

  public ngOnInit() {
    this.fetchJourneysCountPerObjective();
  }

  public refreshChart() {
    if (this.echartsInstance) {
      this.echartsInstance.setOption(this.getChartOptions());
    }
  }

  private fetchJourneysCountPerObjective() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getObjectivesDiagram(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(response => {
        this.objectivesResponse = response.counts;
        this.initChartOnlyOnce();
        this.refreshChart();
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('click', params => {
        this.onBarChartClick(this.objectivesResponse[params.dataIndex].objective);
      });
    }
  }

  private getChartOptions() {
    const numberFormat = new Intl.NumberFormat(this.translateService.currentLang, { useGrouping: true, maximumFractionDigits: 0 });

    return {
      xAxis: {
        type: 'value',
        axisLabel: { formatter: (value: number) => numberFormat.format(value) },
      },
      yAxis: {
        type: 'category',
        axisLabel: { show: false },
        axisTick: { show: false }
      },
      grid: {
        left: 5,
        right: 15,
        top: 0,
        bottom: 20
      },
      ...GraphStyle.TEXT_STYLE,
      series: [
        {
          data: this.getChartData(),
          type: 'bar',
          colorBy: 'data',
          color: this.getChartPalette(),
          label: {
            show: true,
            position: 'insideLeft',
            formatter: '{b}'
          },
          barMaxWidth: 30,
          barCategoryGap: '20%'
        }
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
        ...GraphUtils.getTooltipFormatter({
          numberFormat,
          journeysTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS'),
          personsTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS'),
          minutesTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.MINUTES'),
          kilometersTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.KILOMETERS'),
          totalJourneys: this.objectivesResponse.map(r => r.journeys).reduce((sum, current) => sum + current, 0)
        }),
        ...GraphStyle.TOOLTIP_STYLE
      }
    };
  }

  private getChartData() {
    const objectivesFilter = this.getObjectiveFilter();
    return this.objectivesResponse
      .sort((a, b) => this.getDataValue(a) - this.getDataValue(b))
      .map(entry => ({
        value: this.getDataValue(entry),
        persons: entry.persons,
        journeys: entry.journeys,
        minutes: entry.travelTimeMinutes,
        kilometers: entry.distanceKm,
        name: this.translateObjectiveKey(entry.objective),
        itemStyle: {
          opacity: objectivesFilter.size === 0 || objectivesFilter.has(entry.objective) ? 1 : 0.25
        },
        labelLine: {
          lineStyle: {
            opacity: objectivesFilter.size === 0 || objectivesFilter.has(entry.objective) ? 1 : 0.25
          }
        },
      }));
  }

  private getDataValue(entry: ObjectivesResponse['counts'][0]) {
    switch (this.metric) {
      case 'COUNT': return this.activeToggle === JourneysOrPersons.JOURNEYS ? entry.journeys : entry.persons;
      case 'MINUTES': return entry.travelTimeMinutes;
      case 'KILOMETERS': return entry.distanceKm;
      default: throw exhaustiveCheck(this.metric);
    }
  }

  private translateObjectiveKey(objective: ObjectiveType) {
    return this.translateService.instant('ANALYSIS_OVERVIEW.DIAGRAMS.OBJECTIVE.TYPES.' + objective);
  }

  private getChartPalette() {
    return this.objectivesResponse
      .sort((a, b) => this.getDataValue(a) - this.getDataValue(b))
      .map(entry => ObjectivesChartComponent.FULL_PALETTE[entry.objective]);
  }

  private onBarChartClick(objectiveType: ObjectiveType) {
    const objectivesFilter = this.getObjectiveFilter();
    if (objectivesFilter.has(objectiveType)) {
      objectivesFilter.delete(objectiveType);
    } else {
      objectivesFilter.add(objectiveType);
      if (objectivesFilter.size === ALL_OBJECTIVE_TYPES.length) {
        objectivesFilter.clear();
      }
    }
    this.crossFilteringService.setObjectivesFilter(Array.from(objectivesFilter.values()));
    this.refreshChart();
  }

  private getObjectiveFilter() {
    const objectivesFilter = new Set(this.crossFilteringService.getCrossFilterOptions().objectives);
    if (objectivesFilter.size === ALL_OBJECTIVE_TYPES.length) {
      objectivesFilter.clear();
    }
    return objectivesFilter;
  }
}
