import { Environment } from './environment-interface';

/**
 * Local development environment settings. Change these according to your situation and save as `environment.development.ts`.
 */
export const environment: Environment = {
  production: false,
  apiUri: '', // goes via proxy.conf.json
  fusionAuth: {
    clientId: '0f60b159-2bd0-4001-9719-27fc3839bfc5',
    issuer: 'https://accp.sso.dat.nl'
  },
  appRumMonitor: undefined
};

const appRumMonitorSettings = {
  guestRoleArn: "arn:aws:iam::504213261910:role/PeterNvpDistribution5BF8C-PeterNvpAnalyzerRumMonito-s0bqcL75iTPV",
  identityPoolId: "eu-west-1:f909dfc1-3bbf-4abf-881d-2f9af94c18f2",
  endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",

  applicationId: '6a7f5273-5d50-41c9-9ad1-547e9967c41f',
  region: 'eu-west-1'
};
