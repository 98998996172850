import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { KpiOverviewResponse } from '@shared/resources/analysis/kpi-overview-response';
import { KpiType } from '@shared/resources/analysis/kpi-type';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import { takeUntil } from 'rxjs';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { CsvDataService } from 'src/app/services/csv-data.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartType } from '../analysis-diagram-bar/chart-type';

@Component({
  selector: 'app-kpi-overview',
  templateUrl: './kpi-overview.component.html',
  styleUrl: './kpi-overview.component.scss'
})
export class KpiOverviewComponent implements OnInit {
  @ViewChild('export') divToExport: ElementRef;

  public readonly LOADER_COUNT = 4;

  public kpiTypes: KpiType[] = ['TRAVEL_TIME', 'SPEED', 'DISTANCE', 'MULTIMODAL'];
  public kpis: KpiOverviewResponse['kpis'] = this.kpiTypes.map(type => ({ type, value: 0 }));

  public spinner = new LocalSpinner();

  @Input() public analysisId: number;

  private panelType: ChartType = ChartType.KPI;

  constructor(
    private crossFilteringService: CrossFilteringService,
    private analysisHttpService: AnalysisHttpService,
    private chartService: ChartService,
    private translateService: TranslateService,
    private csvDataService: CsvDataService
  ) {

    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(() => {
      this.fetchKpiValues();
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportKpi(this.divToExport).catch(e => console.error(e));
      }
    });

    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportKpiToClipboard(this.divToExport).catch(e => console.error(e));
      }
    });

    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.KPI');
        const csvData = this.getAggregatedKpiValues();
        this.chartService.exportDataToCsv(fileName, csvData);
      }
    });
  }

  public ngOnInit() {
    this.fetchKpiValues();
  }

  private fetchKpiValues() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getKpiValues(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(response => {
        this.kpis = response.kpis;
      });
  }

  private getAggregatedKpiValues() {
    return this.kpis.map(kpi => {
      return {
        Type: this.translateService.instant(
          'ANALYSIS_OVERVIEW.PANEL.TITLE.KPI_PANEL.'
          + this.kpiTypeToLocale(kpi.type)
        ),
        [this.translateService.instant('MEASUREMENT_UNITS.VALUE')]: Math.round(kpi.value)
      };
    });
  }

  private kpiTypeToLocale(kpiType: KpiType) {
    switch (kpiType) {
      case 'TRAVEL_TIME':
        return 'AVG_TRAVEL_TIME';
      case 'SPEED':
        return 'AVG_SPEED';
      case 'DISTANCE':
        return 'AVG_DISTANCE';
      case 'MULTIMODAL':
        return 'MULTIMODAL';
      default:
        exhaustiveCheck(kpiType);
        return '';
    }
  }

  public triggerExportPersonAttributes() {
    this.analysisHttpService.postAnalysisExportPersonAttributes(this.analysisId, this.crossFilteringService.getCrossFilterOptions())
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe();
  }
}
