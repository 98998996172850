import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Metric } from './metric';

@Component({
  selector: 'app-metric-toggle',
  templateUrl: './metric-toggle.component.html',
  styleUrls: ['./metric-toggle.component.scss']
})
export class MetricToggleComponent {

  @Input() public metric: Metric = 'COUNT';

  @Output() public metricChange: EventEmitter<Metric> = new EventEmitter();

  public journeysOrPersons: JourneysOrPersons;

  constructor(private toggleJourneysCountsService: ToggleJourneysCountsService) {
    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe(journeysOrPersons => {
      this.journeysOrPersons = journeysOrPersons;
    });
  }

}
