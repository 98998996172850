import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ALL_URBAN_DENSITY_TYPES, UrbanDensityType } from '@shared/resources/analysis/personfilter/urban-density-type';
import { UrbanisationResponse } from '@shared/resources/analysis/urbanisation-response';
import * as echarts from 'echarts';
import { takeUntil } from 'rxjs';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { JourneysOrPersons, ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphUtils } from 'src/app/utils/graph-utils';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartService } from '../../services/chart.service';
import { GraphStyle } from '../../utils/constants/graph-style';
import { ChartType } from '../analysis-diagram-bar/chart-type';

@Component({
  selector: 'app-urbanisation-chart',
  templateUrl: './urbanisation-chart.component.html',
  styleUrl: './urbanisation-chart.component.scss'
})
export class UrbanisationChartComponent implements OnInit {

  public readonly HEIGHT_PX = 175;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  private panelType: ChartType = ChartType.PANELISTS_URBANISATION;
  private echartsInstance: echarts.ECharts;
  private urbanisationResponse: UrbanisationResponse['counts'] = [];
  private activeToggle: JourneysOrPersons;

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(changes => {
      if (changes.every(change => change === 'urbanDensities')) {
        this.refreshChart();
      } else {
        this.fetchJourneysPerUrbanisation();
      }
    });

    this.toggleJourneysCountsService.toggleJourneysOrPersonsChanged.pipe(takeUntilDestroyed()).subscribe((activeToggle) => {
      this.activeToggle = activeToggle;
      this.refreshChart();
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });
    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });
    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.PANELISTS_URBANISATION');
        this.chartService.exportDataToCsv(fileName, this.urbanisationResponse);
      }
    });
  }

  public ngOnInit() {
    this.fetchJourneysPerUrbanisation();
  }

  private fetchJourneysPerUrbanisation() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    this.analysisHttpService.getUrbanisationDiagram(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(urbanisationResponse => {
        this.urbanisationResponse = urbanisationResponse.counts;
        this.initChartOnlyOnce();
        this.refreshChart();
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('click', 'series.pie', params => {
        this.onPieClick(this.urbanisationResponse[params.dataIndex].urbanDensity);
      });
    }
  }

  private refreshChart() {
    if (this.echartsInstance) {
      this.echartsInstance.setOption(this.getChartOptions());
    }
  }

  private getChartOptions() {
    return {
      tooltip: {
        trigger: 'item',
        ...GraphUtils.getTooltipFormatter({
          numberFormat: new Intl.NumberFormat(this.translateService.currentLang, { useGrouping: true }),
          journeysTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_JOURNEYS'),
          personsTooltip: this.translateService.instant('ANALYSIS_OVERVIEW.NUMBER_OF_PERSONS'),
          totalJourneys: this.urbanisationResponse.map(r => r.journeys).reduce((sum, current) => sum + current, 0),
          totalPersons: this.urbanisationResponse.map(r => r.persons).reduce((sum, current) => sum + current, 0)
        }),
        ...GraphStyle.TOOLTIP_STYLE
      },
      ...GraphStyle.TEXT_STYLE,
      color: Constants.COLOR_CHART_CATEGORIES,
      legend: {
        top: 0,
        left: 'center',
        selectedMode: false // doesn't perfectly work with our tricks, disable it
      },
      series: [
        {
          type: 'pie',
          radius: ['60%', '100%'],
          center: ['50%', '98%'],
          startAngle: 180, // adjust the start angle
          label: {
            show: true,
            formatter: (param: any) => Math.round(param.percent * 2) + '%' // correct the percentage
          },
          data: this.getChartData()
        }
      ]
    };
  }

  private getChartData() {
    const urbanDensityFilter = this.getUrbanDensityFilter();

    const data: any[] = [];
    this.urbanisationResponse.map(urbanisation => data.push({
      value: this.activeToggle === JourneysOrPersons.JOURNEYS ? urbanisation.journeys : urbanisation.persons,
      journeys: urbanisation.journeys,
      persons: urbanisation.persons,
      name: this.getTranslation('ANALYSIS_OVERVIEW.DIAGRAMS.URBANISATION.TYPES.' + `${urbanisation.urbanDensity}`),
      itemStyle: {
        opacity: urbanDensityFilter.size === 0 || urbanDensityFilter.has(urbanisation.urbanDensity) ? 1 : 0.25
      },
      labelLine: {
        lineStyle: {
          opacity: urbanDensityFilter.size === 0 || urbanDensityFilter.has(urbanisation.urbanDensity) ? 1 : 0.25
        }
      }
    }));

    // make an record to fill the bottom 50%
    data.push({
      value: this.urbanisationResponse.map(h => this.activeToggle === JourneysOrPersons.JOURNEYS ? h.journeys : h.persons).reduce((a, b) => a + b, 0),
      itemStyle: {
        color: 'none'  // stop the chart from rendering this piece
      },
      label: {
        show: false
      }
    });
    return data;
  }

  private getTranslation(key: string) {
    return this.translateService.instant(key);
  }

  private onPieClick(urbanDensityType: UrbanDensityType) {
    const urbanDensityFilter = this.getUrbanDensityFilter();
    if (urbanDensityFilter.has(urbanDensityType)) {
      urbanDensityFilter.delete(urbanDensityType);
    } else {
      urbanDensityFilter.add(urbanDensityType);
      if (urbanDensityFilter.size === ALL_URBAN_DENSITY_TYPES.length) {
        urbanDensityFilter.clear();
      }
    }
    this.crossFilteringService.setUrbanDensitiesFilter(Array.from(urbanDensityFilter.values()));
    this.refreshChart();
  }

  private getUrbanDensityFilter() {
    const urbanDensityFilter = new Set(this.crossFilteringService.getCrossFilterOptions().urbanDensities);
    if (urbanDensityFilter.size === ALL_URBAN_DENSITY_TYPES.length) {
      urbanDensityFilter.clear();
    }
    return urbanDensityFilter;
  }
}
