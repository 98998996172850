@if (isCrossFiltering) {
<div class="filters">{{ 'ANALYSIS_OVERVIEW.FILTERS' | translate}}
  <button mat-button color="primary" class="reset-filter-button" (click)="resetCrossFilter()">
    <mat-icon>restart_alt</mat-icon>
    {{ 'ANALYSIS_OVERVIEW.RESET_CROSS_FILTER' | translate }}
  </button>
</div>
<div class="filter-chip-list">
  <mat-chip-set>
    @for (mode of crossFilterOptions.modes; track mode) {
    <mat-chip [removable]="true" (removed)="removeCrossFilter(mode , 'MODE')">
      {{ 'ANALYSIS_OVERVIEW.CROSS_FILTER_CHIPS.MODES' | translate }}
      {{ ('ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.' + mode.mode) | translate }}
      {{ (mode.uniModal ? 'ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.UNI_MODAL' :
      'ANALYSIS_OVERVIEW.DIAGRAMS.MODAL_SPLIT_CHART.MULTI_MODAL') | translate }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    }
  </mat-chip-set>
  <mat-chip-set>
    @for (householdType of crossFilterOptions.households; track householdType) {
    <mat-chip [removable]="true" (removed)="removeCrossFilter(householdType , 'HOUSEHOLD_TYPE')">
      {{ 'ANALYSIS_OVERVIEW.CROSS_FILTER_CHIPS.HOUSEHOLD' | translate }}
      {{ ('ANALYSIS_OVERVIEW.DIAGRAMS.HOUSEHOLD.TYPES.' + householdType) | translate }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    }
  </mat-chip-set>
  <mat-chip-set>
    @for (urbanDensity of crossFilterOptions.urbanDensities; track urbanDensity) {
    <mat-chip [removable]="true" (removed)="removeCrossFilter(urbanDensity , 'URBANISATION')">
      {{ 'ANALYSIS_OVERVIEW.CROSS_FILTER_CHIPS.URBANISATION' | translate }}
      {{ ('ANALYSIS_OVERVIEW.DIAGRAMS.URBANISATION.TYPES.' + urbanDensity) | translate }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    }
  </mat-chip-set>
  <mat-chip-set>
    @for (objective of crossFilterOptions.objectives; track objective) {
    <mat-chip [removable]="true" (removed)="removeCrossFilter(objective , 'OBJECTIVE')">
      {{ 'ANALYSIS_OVERVIEW.CROSS_FILTER_CHIPS.OBJECTIVE' | translate }}
      {{ ('ANALYSIS_OVERVIEW.DIAGRAMS.OBJECTIVE.TYPES.' + objective) | translate }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    }
  </mat-chip-set>
  <mat-chip-set>
    @for (geoFilterEntry of crossFilterOptions.geoFilterEntries; track geoFilterEntry) {
    @for (code of geoFilterEntry.codes; track code) {
    <mat-chip [removable]="true" (removed)="removeGeoCrossFilter(geoFilterEntry, code)">
      {{ ('ANALYSIS_OVERVIEW.CROSS_FILTER_CHIPS.' + geoFilterEntry.type) | translate }}
      {{ code }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    }
    }
  </mat-chip-set>
</div>
}
