import { AVAILABLE_CHARTS, ChartType, DEFAULT_VISIBLE_CHARTS } from './chart-type';

export interface ChartState {
  type: ChartType;
  visible: boolean;
  expanded: boolean;
}

export const DEFAULT_CHART_STATES = AVAILABLE_CHARTS.map(type => ({
  type,
  visible: DEFAULT_VISIBLE_CHARTS.includes(type),
  expanded: true
}));
