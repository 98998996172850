import { TransportModeType } from './transport-mode-type';

export const ALL_TRANSPORT_MODE_GROUPS = ['CAR', 'BIKE', 'FOOT', 'PUBLIC_TRANSPORT'] as const;
export type TransportModeGroupType = (typeof ALL_TRANSPORT_MODE_GROUPS)[number];

export const TRANSPORT_MODE_GROUPS_TO_MODES: { [key in TransportModeGroupType]: TransportModeType[] } = {
  CAR: ['CAR', 'ECAR', 'TAXI'],
  BIKE: ['BIKE', 'EBIKE'],
  FOOT: ['FOOT'],
  PUBLIC_TRANSPORT: ['BUS', 'LIGHTRAIL', 'METRO', 'PUBLIC_TRANSPORT', 'TRAIN', 'TRAM'],
};
