import { ResidenceFilter } from '@shared/resources/analysis/residencefilter/residence-filter';
import { ResidenceFilterEntry, ResidenceFilterEntryType } from '@shared/resources/analysis/residencefilter/residence-filter-entry';
import { Municipality } from '@shared/resources/geography/municipality';
import { Province } from '@shared/resources/geography/province';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import { forkJoin, of, switchMap } from 'rxjs';
import { GeographyHttpService } from 'src/app/services/http/geography-http.service';

export interface ResidenceFilterOverviewEntry {
  type: ResidenceFilterEntryType,
  geoType: string,
  names: string[];
}

export class ResidenceFilterOverviewGenerator {

  constructor(
    private geographyHttpService: GeographyHttpService
  ) { }

  public generate(residenceFilter: ResidenceFilter) {
    const needToRequestMunicipalities = residenceFilter.entries.some(entry => entry.geoType === 'MUNICIPALITY');
    const needToRequestProvinces = residenceFilter.entries.some(entry => entry.geoType === 'PROVINCE');
    const municipalitiesObs = needToRequestMunicipalities ? this.geographyHttpService.getMunicipalities() : of([] as Municipality[]);
    const provincesObs = needToRequestProvinces ? this.geographyHttpService.getProvinces() : of([] as Province[]);

    return forkJoin([municipalitiesObs, provincesObs])
      .pipe(
        switchMap(([allMunicipalities, allProvinces]) => {
          return of(this.getResidenceFilterEntries(residenceFilter, allMunicipalities, allProvinces));
        })
      );
  }

  private getResidenceFilterEntries(residenceFilter: ResidenceFilter, allMunicipalities: Municipality[], allProvinces: Province[]) {
    return residenceFilter.entries.map(entry => {
      switch (entry.geoType) {
        case 'MUNICIPALITY':
          return this.getMunicipalityEntry(entry, allMunicipalities);
        case 'PROVINCE':
          return this.getProvinceEntry(entry, allProvinces);
        default:
          throw exhaustiveCheck(entry.geoType);
      }
    });
  }

  private getMunicipalityEntry(entry: ResidenceFilterEntry, allMunicipalities: Municipality[]) {
    const municipalities = allMunicipalities.filter(municipality => entry.codes.includes(municipality.code));
    const municipalitiesNames = municipalities.map(municipality => ' ' + municipality.name);
    return this.getResidenceFilterOverviewEntry(entry, municipalitiesNames);
  }

  private getProvinceEntry(entry: ResidenceFilterEntry, allProvinces: Province[]) {
    const provinces = allProvinces.filter(province => entry.codes.includes(province.code));
    const provincesNames = provinces.map(province => ' ' + province.name);
    return this.getResidenceFilterOverviewEntry(entry, provincesNames);
  }

  private getResidenceFilterOverviewEntry(entry: ResidenceFilterEntry, names: string[]) {
    return {
      type: entry.type,
      geoType: entry.geoType + '_OVERVIEW',
      names: names
    } as ResidenceFilterOverviewEntry;
  }
}
