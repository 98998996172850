import { TransportModeGroupType } from './transport-mode-group-type';

export interface DistanceClass {
  minDistanceMeters: number;
  maxDistanceMeters: number | null;
}

export const DISTANCE_CLASSES_PER_MODE_GROUP: Record<TransportModeGroupType, DistanceClass[]> = {

  CAR: [{ minDistanceMeters: 0, maxDistanceMeters: 5000 }, { minDistanceMeters: 5000, maxDistanceMeters: 15000 }, { minDistanceMeters: 15000, maxDistanceMeters: 25000 }, { minDistanceMeters: 25000, maxDistanceMeters: 50000 }, { minDistanceMeters: 50000, maxDistanceMeters: null }],

  BIKE: [{ minDistanceMeters: 0, maxDistanceMeters: 1000 }, { minDistanceMeters: 1000, maxDistanceMeters: 2500 }, { minDistanceMeters: 2500, maxDistanceMeters: 5000 }, { minDistanceMeters: 5000, maxDistanceMeters: 10000 }, { minDistanceMeters: 10000, maxDistanceMeters: 15000 }, { minDistanceMeters: 15000, maxDistanceMeters: null }],

  FOOT: [{ minDistanceMeters: 0, maxDistanceMeters: 100 }, { minDistanceMeters: 100, maxDistanceMeters: 500 }, { minDistanceMeters: 500, maxDistanceMeters: 1000 }, { minDistanceMeters: 1000, maxDistanceMeters: 2500 }, { minDistanceMeters: 2500, maxDistanceMeters: 3750 }, { minDistanceMeters: 3750, maxDistanceMeters: 5000 }, { minDistanceMeters: 5000, maxDistanceMeters: null }],

  PUBLIC_TRANSPORT: [{ minDistanceMeters: 0, maxDistanceMeters: 2500 }, { minDistanceMeters: 2500, maxDistanceMeters: 7500 }, { minDistanceMeters: 7500, maxDistanceMeters: 15000 }, { minDistanceMeters: 15000, maxDistanceMeters: 40000 }, { minDistanceMeters: 40000, maxDistanceMeters: null }]

} as const;

