import { geojsonType } from '@turf/turf';
import { Feature, FeatureCollection, MultiPolygon, Polygon } from 'geojson';

export class GeoJsonUtils {
  public static async getValidatedPolygonOrMultiPolygonFeature(file: File): Promise<Feature<Polygon | MultiPolygon>> {
    const fileContent = await file.text();
    let parsedGeoJson: FeatureCollection | Feature<Polygon | MultiPolygon>;

    try {
      parsedGeoJson = JSON.parse(fileContent);
      geojsonType(parsedGeoJson, parsedGeoJson.type as 'Feature' | 'FeatureCollection', 'GeoJSON must be a Feature or FeatureCollection');
    } catch {
      throw new Error('Uploaded file is not a valid GeoJSON');
    }

    if (parsedGeoJson.type === 'FeatureCollection') {
      if (!parsedGeoJson.features || parsedGeoJson.features.length !== 1) {
        throw new Error('GeoJSON FeatureCollection must contain exactly one feature');
      }
      return this.getValidPolygonOrMultiPolygon(parsedGeoJson.features[0]);
    }

    return this.getValidPolygonOrMultiPolygon(parsedGeoJson);
  }

  private static getValidPolygonOrMultiPolygon(feature: Feature): Feature<Polygon | MultiPolygon> {
    if (!['Polygon', 'MultiPolygon'].includes(feature.geometry.type)) {
      throw new Error('Feature must be a Polygon or MultiPolygon');
    }

    geojsonType(feature.geometry, feature.geometry.type as 'Polygon' | 'MultiPolygon', 'Feature must be a Polygon or MultiPolygon');

    return feature as Feature<Polygon | MultiPolygon>;
  }

  public static isFeatureValidPolygonOrMultiPolygon(feature: Feature<Polygon | MultiPolygon>): boolean {
    if (feature.geometry.type === 'Polygon') {
      return feature.geometry.coordinates.some((linearRing) => linearRing.length > 2);
    }

    if (feature.geometry.type === 'MultiPolygon') {
      return feature.geometry.coordinates.every((polygon) =>
        polygon.some((linearRing) => linearRing.length > 2)
      );
    }

    return false;
  }
}
