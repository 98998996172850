import { Component, Input } from '@angular/core';
import { PersonFilter } from '@shared/resources/analysis/personfilter/personal-filter';
import { ResidenceFilterEntry } from '@shared/resources/analysis/residencefilter/residence-filter-entry';
import { Municipality } from '@shared/resources/geography/municipality';
import { Province } from '@shared/resources/geography/province';

@Component({
  selector: 'app-residence-filter',
  templateUrl: './residence-filter.component.html',
  styleUrl: './residence-filter.component.scss'
})
export class ResidenceFilterComponent {
  @Input() public municipalities: Municipality[] = [];
  @Input() public provinces: Province[] = [];
  @Input() public personFilter: PersonFilter;

  public onAddResidenceFilterClick() {
    const residenceFilterEntry: ResidenceFilterEntry = { codes: [], geoType: 'MUNICIPALITY', type: 'PLACE_OF_RESIDENCE' };
    this.personFilter.placeOfResidence.entries.push(residenceFilterEntry);
  }

  public onResidenceFilterEntryToBeDeleteEvent(residenceFilterEntryToBeDeleted: ResidenceFilterEntry) {
    this.personFilter.placeOfResidence.entries = this.personFilter.placeOfResidence.entries.filter((entry) => entry !== residenceFilterEntryToBeDeleted);
  }
}
