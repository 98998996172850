<div class="filters">
  <div class="add-filter">
    @for (residenceFilterEntry of personFilter.placeOfResidence.entries; track $index) {
    <app-residence-filter-entry [residenceFilterEntry]="residenceFilterEntry"
      (deleteEntry)="onResidenceFilterEntryToBeDeleteEvent($event)" [municipalities]="municipalities"
      [provinces]="provinces"></app-residence-filter-entry>
    }
    <button mat-stroked-button data-cy="add-residence-filter" class="add-button" color="primary"
      [disabled]="personFilter.placeOfResidence.entries.length === 1" (click)="onAddResidenceFilterClick()">+ {{
      'RESIDENCE_FILTER.BUTTON_ADD_FILTER' |
      translate }}</button>
  </div>
</div>
