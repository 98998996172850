
// Default order of charts

import { LocalStorageConstants } from 'src/app/utils/constants/local-storage-constants';

export enum ChartType {
  RELIABILITY = 'RELIABILITY',
  KPI = 'KPI',
  MOVEMENTS = 'MOVEMENTS',
  OBJECTIVES = 'OBJECTIVES',
  ORIGINS_AND_DESTINATIONS = 'ORIGINS_AND_DESTINATIONS',
  MODE_CHAIN = 'MODE_CHAIN',
  TREND_GROUPED_MODES = 'TREND_GROUPED_MODES',
  PANELISTS_HOUSEHOLDS = 'PANELISTS_HOUSEHOLDS',
  PANELISTS_URBANISATION = 'PANELISTS_URBANISATION',
  CAR_DISTANCE_HISTOGRAM = 'CAR_DISTANCE_HISTOGRAM',
  BIKE_DISTANCE_HISTOGRAM = 'BIKE_DISTANCE_HISTOGRAM',
  FOOT_DISTANCE_HISTOGRAM = 'FOOT_DISTANCE_HISTOGRAM',
  PUBLIC_TRANSPORT_DISTANCE_HISTOGRAM = 'PUBLIC_TRANSPORT_DISTANCE_HISTOGRAM'
}

const HIDDEN_CHARTS = localStorage.getItem(LocalStorageConstants.FEATURE_FLAG) === 'true'
  ? []
  : [ChartType.RELIABILITY];

export const AVAILABLE_CHARTS = Object.values(ChartType).filter(type => !HIDDEN_CHARTS.includes(type));

export const DEFAULT_VISIBLE_CHARTS = [
  ChartType.KPI,
  ChartType.MOVEMENTS,
  ChartType.OBJECTIVES,
  ChartType.ORIGINS_AND_DESTINATIONS,
  ChartType.MODE_CHAIN,
  ChartType.TREND_GROUPED_MODES,
  ChartType.PANELISTS_HOUSEHOLDS
];
