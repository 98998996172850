import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Analysis } from '@shared/resources/analysis/analysis';
import { cloneDeep } from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { MessageService } from 'src/app/services/message.service';
import { ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';
import { LocalStorageConstants } from 'src/app/utils/constants/local-storage-constants';
import { ManageChartsDialogComponent } from '../manage-charts-dialog/manage-charts-dialog.component';
import { ChartState, DEFAULT_CHART_STATES } from './chart-state';
import { ChartType } from './chart-type';

@Component({
  selector: 'app-analysis-diagram-bar',
  templateUrl: './analysis-diagram-bar.component.html',
  styleUrl: './analysis-diagram-bar.component.scss'
})
export class AnalysisDiagramBarComponent {

  @Input({ required: true }) public analysis: Analysis | null;

  public orderedChartStates: ChartState[];
  public availableChartCount: number;

  public crossFilterValid = true;
  public exportInProgress = false;
  public exportingPersonAttributes = false;

  constructor(
    private crossFilteringService: CrossFilteringService,
    private chartService: ChartService,
    private toggleJourneysCountsService: ToggleJourneysCountsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private translateService: TranslateService,
    private analysisHttpService: AnalysisHttpService
  ) {
    this.initOrderedChartStates();

    this.chartService.exportOdMatrixInProgress.pipe(takeUntilDestroyed()).subscribe(inProgress => {
      this.exportInProgress = inProgress;
    });
  }

  public revertCrossFilter() {
    this.crossFilteringService.restorePreviousCrossFilter();
  }

  public onChartDropped(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.orderedChartStates, event.previousIndex, event.currentIndex);
      this.persistOrderedChartStates();
    }
  }

  public onChartExpandedChange() {
    this.persistOrderedChartStates();
  }

  public exportChart(panel: string) {
    if (panel in ChartType) {
      this.chartService.triggerExport(panel as ChartType);
    }
  }

  public copyChartToClipboard(panel: string) {
    if (panel in ChartType) {
      this.chartService.triggerCopyToClipboard(panel as ChartType);
    }
  }

  public exportChartToCsv(panel: string) {
    if (this.authService.isTrialUser) {
      this.messageService.showOkButtonDialog('ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_NOT_ALLOWED');
    } else if (panel in ChartType) {
      this.chartService.triggerExportCsv(panel as ChartType);
    }
  }

  public exportOdMatrix(panel: string) {
    if (this.authService.isTrialUser) {
      this.messageService.showOkButtonDialog('ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_NOT_ALLOWED');
    } else if (panel in ChartType) {
      this.chartService.triggerExportOdMatrix(panel as ChartType);
    }
  }

  public onManageDiagramsClick() {
    const dialogRef = ManageChartsDialogComponent.show(this.dialog, this.orderedChartStates);
    dialogRef.afterClosed().subscribe(chartStates => {
      if (chartStates) {
        this.orderedChartStates = chartStates;
        this.persistOrderedChartStates();
        this.availableChartCount = this.orderedChartStates.filter(chart => !chart.visible).length;
      }
    });
  }

  private initOrderedChartStates() {
    // Use the default as leading, and adjust according to what is found in localstorage
    this.orderedChartStates = cloneDeep(DEFAULT_CHART_STATES);

    try {
      const success = this.loadOrderedChartStatesFromLocalStorage();
      if (!success) {
        // Backwards compatibility with old localstorage keys
        this.loadDeprecatedChartStatesFromLocalStorage();
      }
    } catch (e) {
      console.error(e);
    }

    this.availableChartCount = this.orderedChartStates.filter(chart => !chart.visible).length;
  }

  private loadOrderedChartStatesFromLocalStorage() {
    const orderedChartState = JSON.parse(localStorage.getItem(LocalStorageConstants.ORDERED_CHARTS_STATE) as string);
    if (orderedChartState && Array.isArray(orderedChartState)) {
      this.orderedChartStates.sort((a, b) => {
        const indexA = orderedChartState.findIndex(e => e.type === a.type);
        const indexB = orderedChartState.findIndex(e => e.type === b.type);
        return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
      });

      for (const chartState of this.orderedChartStates) {
        const storedChartState = orderedChartState.find(e => e.type === chartState.type);
        if (typeof storedChartState?.visible === 'boolean') {
          chartState.visible = storedChartState.visible;
        }
        if (typeof storedChartState?.expanded === 'boolean') {
          chartState.expanded = storedChartState.expanded;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  private loadDeprecatedChartStatesFromLocalStorage() {
    const chartsOrder = JSON.parse(localStorage.getItem(LocalStorageConstants.CHARTS_ORDER_DEPRECATED) as string);
    if (chartsOrder && Array.isArray(chartsOrder)) {
      this.orderedChartStates.sort((a, b) => {
        const indexA = chartsOrder.indexOf(a.type);
        const indexB = chartsOrder.indexOf(b.type);
        return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
      });
    }

    const chartsExpanded = JSON.parse(localStorage.getItem(LocalStorageConstants.CHARTS_EXPANDED_DEPRECATED) as string);
    if (chartsExpanded && typeof chartsExpanded === 'object' && !Array.isArray(chartsExpanded)) {
      for (const chartState of this.orderedChartStates) {
        if (typeof chartsExpanded[chartState.type] === 'boolean') {
          chartState.expanded = chartsExpanded[chartState.type];
        }
      }
    }

    this.persistOrderedChartStates();
    localStorage.removeItem(LocalStorageConstants.CHARTS_ORDER_DEPRECATED);
    localStorage.removeItem(LocalStorageConstants.CHARTS_EXPANDED_DEPRECATED);
  }

  private persistOrderedChartStates() {
    localStorage.setItem(LocalStorageConstants.ORDERED_CHARTS_STATE, JSON.stringify(this.orderedChartStates));
  }

  public exportPersonAttributes() {
    const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_TITLE.PERSON_ATTRIBUTES');
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    this.exportingPersonAttributes = true;
    if (this.analysis) {
      this.analysisHttpService.postAnalysisExportPersonAttributes(this.analysis.analysisId, crossFilterOptions).subscribe(response => {
        this.chartService.exportDataToCsv(fileName, response.counts);
        this.exportingPersonAttributes = false;
      });
    }
  }
}
