export interface TooltipVars {
  numberFormat: Intl.NumberFormat;
  journeysTooltip: string;
  totalJourneys?: number;
  personsTooltip?: string;
  totalPersons?: number;
  minutesTooltip?: string;
  kilometersTooltip?: string;
  excludeColorCircle?: boolean;
}

export class GraphUtils {
  /**
   * Creates a formatter for tooltips in Echarts. Expects params.data.journeys and params.data.persons to exist. Will add percentages if totalPersons is given
   */
  public static getTooltipFormatter(tooltipVars: TooltipVars) {
    return {
      formatter: function (params: any) {
        return GraphUtils.getTooltipFormatterExpression(tooltipVars, params);
      }
    };
  }

  /**
   * Will return the expression used in the TooltipFormatter. Used for more complex tooltips such as ModalChart.
   * Assumes data.journeys and data.persons (if applicable) exist
   */
  public static getTooltipFormatterExpression(tooltipVars: TooltipVars, params: any): string {
    const nf = tooltipVars.numberFormat;

    let colorCircle = '';
    if (!tooltipVars.excludeColorCircle) {
      colorCircle += `<div style="display: inline-block; width: 9px; height: 9px; background-color: ${params.color}; border-radius: 50%; margin-right: 5px;"></div>`;
    }

    let journeysLine = `<br/>${tooltipVars.journeysTooltip}: ${nf.format(params.data.journeys)}`;
    if (tooltipVars.totalJourneys) {
      journeysLine += ` (${Math.round((params.data.journeys / tooltipVars.totalJourneys) * 100)}%)`;
    }

    let personsLine = '';
    if (tooltipVars.personsTooltip) {
      personsLine += `<br/>${tooltipVars.personsTooltip}: ${nf.format(params.data.persons)}`;
      if (tooltipVars.totalPersons) {
        personsLine += ` (${Math.round((params.data.persons / tooltipVars.totalPersons) * 100)}%)`;
      }
    }

    let minutesLine = '';
    if (tooltipVars.minutesTooltip) {
      minutesLine += `<br/>${tooltipVars.minutesTooltip}: ${nf.format(params.data.minutes)}`;
    }

    let kilometersLine = '';
    if (tooltipVars.kilometersTooltip) {
      kilometersLine += `<br/>${tooltipVars.kilometersTooltip}: ${nf.format(params.data.kilometers)}`;
    }

    return `${colorCircle}<b>${params.name}</b>${journeysLine}${personsLine}${minutesLine}${kilometersLine}`;
  }

  // Usually our datasource is not provided as a 2d array, but sometimes it is the easiest way to make it work
  public static getTooltipFormatterForArrayData(tooltipVars: { numberFormat: Intl.NumberFormat; journeysTooltip: string, personsTooltip?: string, totalJourneys?: number, totalPersons?: number, excludeColorCircle?: boolean; }) {
    return {
      formatter: function (params: any) {
        const nf = tooltipVars.numberFormat;

        let colorCircle = '';
        if (!tooltipVars.excludeColorCircle) {
          colorCircle += `<div style="display: inline-block; width: 9px; height: 9px; background-color: ${params.color}; border-radius: 50%; margin-right: 5px;"></div>`;
        }

        let journeysLine = `<br/>${tooltipVars.journeysTooltip}: ${nf.format(params[0].data[2])}`;
        if (tooltipVars.totalJourneys) {
          journeysLine += ` (${Math.round((params[0].data[2] / tooltipVars.totalJourneys) * 100)}%)`;
        }

        let personsLine = '';
        if (tooltipVars.personsTooltip) {
          personsLine += `<br/>${tooltipVars.personsTooltip}: ${nf.format(params[0].data[3])}`;
          if (tooltipVars.totalPersons) {
            personsLine += ` (${Math.round((params[0].data[3] / tooltipVars.totalPersons) * 100)}%)`;
          }
        }
        return `${colorCircle}<b>${params[0].data[0]}</b>${journeysLine}${personsLine}`;
      }
    };
  }
}
