import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ALL_TRANSPORT_MODE_GROUPS, TRANSPORT_MODE_GROUPS_TO_MODES, TransportModeGroupType } from '@shared/resources/analysis/transport-mode-group-type';
import { TrendPerModeGroupResponse } from '@shared/resources/analysis/trend-per-mode-group-response';
import * as echarts from 'echarts';
import { takeUntil } from 'rxjs';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphStyle } from 'src/app/utils/constants/graph-style';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartType } from '../analysis-diagram-bar/chart-type';

@Component({
  selector: 'app-trends-by-modegroup',
  templateUrl: './trends-by-modegroup.component.html',
  styleUrl: './trends-by-modegroup.component.scss'
})
export class TrendsByModegroupComponent implements OnInit {

  public static readonly LINE_WIDTH = 1;

  public readonly HEIGHT_PX = 240;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input() public analysisId: number;

  public spinner = new LocalSpinner();

  public stacked = true;

  private panelType: ChartType = ChartType.TREND_GROUPED_MODES;
  private echartsInstance: echarts.ECharts;
  private modegroupTrendResponse: TrendPerModeGroupResponse['trend'] = [];

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(changes => {
      if (changes.every(change => change === 'modes')) {
        this.refreshChart();
      } else {
        this.fetchTrendsByModegroup();
      }
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(panelType => {
      if (this.panelType === panelType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.TREND_GROUPED_MODES');
        this.chartService.exportDataToCsv(fileName, this.modegroupTrendResponse.map(obj => ({ date: obj.isoFormattedDate, ...obj.values })));
      }
    });
  }

  public ngOnInit() {
    this.fetchTrendsByModegroup();
  }

  public refreshChart() {
    if (this.echartsInstance) {
      this.echartsInstance.setOption(this.getChartOptions(), true);
    }
  }

  private fetchTrendsByModegroup() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();

    this.analysisHttpService.getModeGroupTrend(this.analysisId, crossFilterOptions)
      .pipe(this.spinner.register(), takeUntil(this.crossFilteringService.filterOptionsChanged))
      .subscribe(journeysCount => {
        this.modegroupTrendResponse = journeysCount.trend;
        this.initChartOnlyOnce();
        this.refreshChart();
      });
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('mousemove', () => this.echartsInstance.getZr().setCursorStyle('default'));
    }
  }

  private getChartOptions() {
    const tooltipNumberFormat = new Intl.NumberFormat(this.translateService.currentLang, { maximumFractionDigits: 0 });

    return {
      xAxis: {
        type: 'time',
        ...GraphStyle.AXIS_STYLE
      },
      yAxis: {
        axisLabel: {
          ...GraphStyle.AXIS_STYLE.axisLabel,
          formatter: '{value}%'
        },
        ...(this.stacked ? {
          // Ensure autogenerated axis ticks never exceeds 100%
          max: (value: { min: number, max: number; }) => value.max >= 100 ? 100 : undefined
        } : {})
      },
      ...GraphStyle.TEXT_STYLE,
      grid: {
        left: 40,
        right: 15,
        top: 25,
        bottom: 20
      },
      series: ALL_TRANSPORT_MODE_GROUPS.map(modeGroup => ({
        name: this.getModegroupTranslation(modeGroup),
        data: this.getChartData(modeGroup),
        type: 'line',
        smooth: true,
        color: Constants.COLORS_PER_MODE[modeGroup === 'PUBLIC_TRANSPORT' ? 'TRAIN' : TRANSPORT_MODE_GROUPS_TO_MODES[modeGroup][0]],
        showSymbol: false,
        lineStyle: {
          width: TrendsByModegroupComponent.LINE_WIDTH
        },
        ...(this.stacked ? {
          stack: 'total',
          areaStyle: {},
        } : {})
      })),
      legend: {
        left: 34
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        ...TrendsByModegroupComponent.getTooltipFormatter(tooltipNumberFormat),
        ...GraphStyle.TOOLTIP_STYLE
      },
      title: {
        bottom: 25,
        left: 'center',
        text: this.modegroupTrendResponse.length > 0
          ? this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TREND_GROUPED_MODES.SUBTITLE')
          : this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TREND_GROUPED_MODES.DATE_RANGE_TOO_SHORT'),
        textStyle: {
          ...GraphStyle.TEXT_STYLE.textStyle,
          fontWeight: 'normal'
        }
      },
    };
  }

  private getChartData(modeGroup: TransportModeGroupType) {
    return this.modegroupTrendResponse.map(entry => [entry.isoFormattedDate, entry.values[modeGroup] * 100]);
  }

  public static getTooltipFormatter(numberFormat: Intl.NumberFormat) {
    return {
      formatter: function (params: any) {
        const date = `<b>${params[0].data[0]}</b></br>`;
        const labels = (params as any[]).reverse().map(param =>
          `<div style="display: inline-block; width: 9px; height: 9px; background-color: ${param.color}; border-radius: 50%; margin-right: 5px;"></div>
          <b>${param.seriesName}:</b> ${numberFormat.format(param.data[1])}%`
        ).join('</br>');
        return date.concat(labels);
      }
    };
  }

  private getModegroupTranslation(key: TransportModeGroupType) {
    return this.translateService.instant(`ANALYSIS_OVERVIEW.DIAGRAMS.TREND_GROUPED_MODES.${key}`);
  }
}
