import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GeoFilterEntry } from '@shared/resources/analysis/geofilter/geo-filter-entry';
import { CustomRegion } from '@shared/resources/geography/custom-region';
import { Municipality } from '@shared/resources/geography/municipality';
import { Province } from '@shared/resources/geography/province';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-geo-filter-entry',
  templateUrl: './geo-filter-entry.component.html',
  styleUrl: './geo-filter-entry.component.scss',
})
export class GeoFilterEntryComponent {
  @Input({ required: true }) public geoFilterEntry: GeoFilterEntry;
  @Input() public locked: boolean;

  @Output() public filterTypeChanged: EventEmitter<GeoFilterEntry> = new EventEmitter();
  @Output() public geoTypeChanged: EventEmitter<GeoFilterEntry> = new EventEmitter();
  @Output() public delete: EventEmitter<GeoFilterEntry> = new EventEmitter();

  public readonly types: GeoFilterEntry['type'][] = ['ORIGIN', 'DESTINATION', 'PASS_THROUGH', 'INTERSECT'];

  public geoTypes: GeoFilterEntry['geoType'][] = ['PROVINCE', 'MUNICIPALITY', 'POLYGON'];

  private _provinces: Province[] = [];
  private _municipalities: Municipality[] = [];
  private _customRegions: CustomRegion[] = [];

  @Input({ required: true }) set municipalities(municipalities: Municipality[]) {
    this._municipalities = municipalities;
    this.filteredMunicipalities.next(municipalities);
  }

  get municipalities(): Municipality[] {
    return this._municipalities;
  }

  get provinces(): Province[] {
    return this._provinces;
  }

  @Input({ required: true }) set provinces(provinces: Province[]) {
    this._provinces = provinces;
    this.filteredProvinces.next(provinces);
  }

  @Input({ required: true }) set customRegions(customRegions: CustomRegion[]) {
    this._customRegions = customRegions;
    this.filteredCustomRegions.next(customRegions);
    this.geoTypes = this.customRegions.length === 0
      ? ['PROVINCE', 'MUNICIPALITY', 'POLYGON']
      : ['PROVINCE', 'MUNICIPALITY', 'CUSTOM_REGION', 'POLYGON'];
  }

  get customRegions(): CustomRegion[] {
    return this._customRegions;
  }

  public filteredProvinces = new BehaviorSubject<Province[]>([]);
  public filteredMunicipalities = new BehaviorSubject<Municipality[]>([]);
  public filteredCustomRegions = new BehaviorSubject<CustomRegion[]>([]);
  public filteredCustomProvinces = new BehaviorSubject<CustomRegion[]>([]);

  public filterProvinces(searchText: string) {
    if (!this.provinces) {
      return;
    }
    if (!searchText) {
      this.filteredProvinces.next([...this.provinces]);
      return;
    }

    this.filteredProvinces.next(
      this.provinces.filter(
        (province) =>
          province.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          province.code.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }


  public filterMunicipalities(searchText: string) {
    if (!this.municipalities) {
      return;
    }
    if (!searchText) {
      this.filteredMunicipalities.next([...this.municipalities]);
      return;
    }

    this.filteredMunicipalities.next(
      this.municipalities.filter(
        (municipality) =>
          municipality.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          municipality.code.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }

  public filterCustomRegions(searchText: string) {
    if (!this.customRegions) {
      return;
    }
    if (!searchText) {
      this.filteredCustomRegions.next([...this.customRegions]);
      return;
    }

    this.filteredCustomRegions.next(
      this.customRegions.filter(
        (municipality) =>
          municipality.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          municipality.id.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }

  public onFilterTypeChanged() {
    this.filterTypeChanged.emit(this.geoFilterEntry);
  }

  public onGeoTypeChanged() {
    if (this.geoFilterEntry.geoType === 'POLYGON') {
      this.geoFilterEntry.geometry = {
        type: "Polygon",
        coordinates: []
      };
      delete (this.geoFilterEntry as any).codes;
      delete (this.geoFilterEntry as any).customRegionIds;
    } else if (this.geoFilterEntry.geoType === 'CUSTOM_REGION') {
      this.geoFilterEntry.customRegionIds = [];
      delete (this.geoFilterEntry as any).codes;
      delete (this.geoFilterEntry as any).geometry;
    } else {
      this.geoFilterEntry.codes = [];
      delete (this.geoFilterEntry as any).geometry;
      delete (this.geoFilterEntry as any).customRegionIds;
    }

    this.geoTypeChanged.emit(this.geoFilterEntry);
  }

  public onDeleteClick(geoFilterEnrtyToDelete: GeoFilterEntry) {
    this.delete.emit(geoFilterEnrtyToDelete);
  }
}
