import { Component, Input } from '@angular/core';
import { Analysis } from '@shared/resources/analysis/analysis';
import { AnalysisListItem } from '@shared/resources/analysis/analysis-list-item';
import { defer } from 'lodash';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { ToggleJourneysCountsService } from 'src/app/services/toggle-journeys-counts.service';

/**
 * Simple wrapper around app-analysis-diagram-bar but so that each column has
 * its own CrossFilteringService and ToggleJourneysCountsService provided.
 */
@Component({
  selector: 'app-compare-column',
  templateUrl: './compare-column.component.html',
  styleUrl: './compare-column.component.scss',
  providers: [CrossFilteringService, ToggleJourneysCountsService, ChartService]
})
export class CompareColumnComponent {

  @Input({ required: true }) public analysisListItems: AnalysisListItem[];

  public selectedAnalysis: Analysis | null = null; // selected in the dropdown
  public activeAnalysis: Analysis | null = null; // loaded in the diagram-bar

  constructor(private crossFilteringService: CrossFilteringService) {

  }

  public onSelectedAnalysisChange() {
    this.activeAnalysis = null;
    defer(() => {
      // defer to make sure child components have a chance to "reset"
      this.crossFilteringService.resetFilter();
      this.activeAnalysis = this.selectedAnalysis;
    });
  }

}
