import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import type * as i18n_en from '../assets/i18n/en.json';
import type * as i18n_nl from '../assets/i18n/nl.json';
import { appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { NoLicenseComponent } from './auth/no-license/no-license.component';
import { AnalysisCountsComponent } from './components/analysis-counts/analysis-counts.component';
import { AnalysisDiagramBarComponent } from './components/analysis-diagram-bar/analysis-diagram-bar.component';
import { AnalysisFilterOverviewComponent } from './components/analysis-filter-overview/analysis-filter-overview.component';
import { DistanceHistogramByModegroupComponent } from './components/car-journey-frequency-chart/distance-histogram-by-modegroup.component';
import { CompareColumnComponent } from './components/compare-column/compare-column.component';
import { CrossFilterChipsComponent } from './components/cross-filter-chips/cross-filter-chips.component';
import { DisclaimerDialogComponent } from './components/disclaimer-dialog/disclaimer-dialog.component';
import { HouseholdChartComponent } from './components/household-chart/household-chart.component';
import { KpiBlockComponent } from './components/kpi-overview/kpi-block/kpi-block.component';
import { KpiOverviewComponent } from './components/kpi-overview/kpi-overview.component';
import { ManageChartsDialogComponent } from './components/manage-charts-dialog/manage-charts-dialog.component';
import { MetricToggleComponent } from './components/metric-toggle/metric-toggle.component';
import { ModalSplitChartComponent } from './components/modal-split-chart/modal-split-chart.component';
import { ModeTransitionsChartComponent } from './components/mode-transitions-chart/mode-transitions-chart.component';
import { NvpAnalysisCompareComponent } from './components/nvp-analysis-compare/nvp-analysis-compare.component';
import { AnalysisMetadataComponent } from './components/nvp-analysis-input/analysis-metadata/analysis-metadata.component';
import {
  DateTimeFilterEntryComponent
} from './components/nvp-analysis-input/date-time-filter/date-time-filter-entry/date-time-filter-entry.component';
import { DateTimeFilterComponent } from './components/nvp-analysis-input/date-time-filter/date-time-filter.component';
import { GeoFilterEntryComponent } from './components/nvp-analysis-input/geo-filter/geo-filter-entry/geo-filter-entry.component';
import { GeoFilterComponent } from './components/nvp-analysis-input/geo-filter/geo-filter.component';
import { NvpAnalysisInputComponent } from './components/nvp-analysis-input/nvp-analysis-input.component';
import { PersonFilterComponent } from './components/nvp-analysis-input/person-filter/person-filter.component';
import { ResidenceFilterEntryComponent } from './components/nvp-analysis-input/residence-filter/residence-filter-entry/residence-filter-entry.component';
import { ResidenceFilterComponent } from './components/nvp-analysis-input/residence-filter/residence-filter.component';
import { TransportFilterComponent } from './components/nvp-analysis-input/transport-filter/transport-filter.component';
import { NvpAnalysisListComponent } from './components/nvp-analysis-list/nvp-analysis-list.component';
import { NvpAnalysisMapComponent } from './components/nvp-analysis-map/nvp-analysis-map.component';
import { ObjectivesChartComponent } from './components/objectives-chart/objectives-chart.component';
import { OdFlowsChartComponent } from './components/od-flows-chart/od-flows-chart.component';
import { ReliabilityStatisticsComponent } from './components/reliability-statistics/reliability-statistics.component';
import { ShareAnalysisDialogComponent } from './components/share-analysis-dialog/share-analysis-dialog.component';
import { TrendsByModegroupComponent } from './components/trends-by-modegroup/trends-by-modegroup.component';
import { UrbanisationChartComponent } from './components/urbanisation-chart/urbanisation-chart.component';
import { SharedModule } from './modules/shared/shared.module';
import { AuthInterceptor } from './services/http/interceptors/auth.interceptor';
import { ErrorInterceptor } from './services/http/interceptors/error.interceptor';
import { DynamicLocaleId, LanguageUtils } from './utils/language-utils';

// Use TypeScript compiler to check for i18n omissions in language files (both should be the same)
declare class match_i18n<Expected, Actual extends Expected> {
}

declare const i18nChecks: [
  match_i18n<typeof i18n_en, typeof i18n_nl>, // NL matches EN ?
  match_i18n<typeof i18n_nl, typeof i18n_en> // EN matches NL ?
];

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent,
    NvpAnalysisListComponent,
    NvpAnalysisInputComponent,
    NvpAnalysisMapComponent,
    NvpAnalysisCompareComponent,
    AnalysisMetadataComponent,
    AnalysisCountsComponent,
    PersonFilterComponent,
    GeoFilterComponent,
    GeoFilterEntryComponent,
    DateTimeFilterComponent,
    DateTimeFilterEntryComponent,
    TransportFilterComponent,
    ModalSplitChartComponent,
    TrendsByModegroupComponent,
    OdFlowsChartComponent,
    HouseholdChartComponent,
    UrbanisationChartComponent,
    CrossFilterChipsComponent,
    ObjectivesChartComponent,
    ModeTransitionsChartComponent,
    CrossFilterChipsComponent,
    NoLicenseComponent,
    DisclaimerDialogComponent,
    DisclaimerDialogComponent,
    AnalysisDiagramBarComponent,
    CompareColumnComponent,
    KpiOverviewComponent,
    KpiBlockComponent,
    ReliabilityStatisticsComponent,
    AnalysisFilterOverviewComponent,
    ResidenceFilterComponent,
    ResidenceFilterEntryComponent,
    AnalysisFilterOverviewComponent,
    MetricToggleComponent,
    DistanceHistogramByModegroupComponent,
    ManageChartsDialogComponent,
    ShareAnalysisDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,

    SharedModule,
    ReactiveFormsModule,

    // Other libs
    TranslateModule.forRoot(LanguageUtils.getTranslateModuleConfig()),
    AuthConfigModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
