import { Injectable, OnDestroy } from '@angular/core';
import { CrossFilterGeoEntryType, CrossFilterGeoType } from '@shared/resources/analysis/cross-filter-geo-entry';
import { CREATE_EMPTY_CROSS_FILTER_OPTIONS, CrossFilterOptions, ModeWithUniModal } from '@shared/resources/analysis/cross-filter-options';
import { ObjectiveType } from '@shared/resources/analysis/objective-type';
import { HouseholdType } from '@shared/resources/analysis/personfilter/household-type';
import { UrbanDensityType } from '@shared/resources/analysis/personfilter/urban-density-type';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { buffer, debounceTime, map, Observable, Subject } from 'rxjs';
import { CrossFilterActionType, CustomEventsConstants } from '../utils/constants/custom-events-constants';
import { AppMonitorService } from './app-monitor.service';

export type CrossFilterOptionsChange = Exclude<keyof CrossFilterOptions, 'geoFilterEntries'> | Lowercase<`${CrossFilterGeoEntryType}_${CrossFilterGeoType}`>;

@Injectable()
export class CrossFilteringService implements OnDestroy {

  public readonly EMPTY_CROSS_FILTER_OPTIONS = CREATE_EMPTY_CROSS_FILTER_OPTIONS();

  public filterOptionsChanged: Observable<CrossFilterOptionsChange[]>;

  private filterOptionsChangedSource = new Subject<CrossFilterOptionsChange[]>();

  private crossFilter = CREATE_EMPTY_CROSS_FILTER_OPTIONS();
  private previousCrossFilter = CREATE_EMPTY_CROSS_FILTER_OPTIONS();

  private ready = false;

  constructor(private appMonitorService: AppMonitorService) {
    const filterOptionsChangedObs = this.filterOptionsChangedSource.asObservable();
    this.filterOptionsChanged = filterOptionsChangedObs.pipe(
      buffer(filterOptionsChangedObs.pipe(debounceTime(750))), // debounce without discarding values
      map(bufferedChanges => Array.from(new Set(bufferedChanges.flat()))) // flatten to unique list
    );

    this.resetFilter();
    this.ready = true;
  }

  public ngOnDestroy() {
    this.filterOptionsChangedSource.complete();
  }

  public getCrossFilterOptions() {
    return this.crossFilter;
  }

  public resetFilter() {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter = CREATE_EMPTY_CROSS_FILTER_OPTIONS();
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.RESET);
  }

  public setModesFilter(modes: ModeWithUniModal[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.modes = modes;
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.MODE);
  }

  public setHouseholdsFilter(households: HouseholdType[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.households = households;
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.HOUSEHOLD);
  }

  public setUrbanDensitiesFilter(urbanDensities: UrbanDensityType[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.urbanDensities = urbanDensities;
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.URBAN_DENSITY);
  }

  public setObjectivesFilter(objectiveType: ObjectiveType[]) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter.objectives = objectiveType;
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.OBJECTIVE);
  }

  public toggleGeoFilterEntry(type: CrossFilterGeoEntryType, geoType: CrossFilterGeoType, code: string) {
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    const existingEntry = this.crossFilter.geoFilterEntries.find(entry => entry.type === type && entry.geoType === geoType);
    if (existingEntry) {
      if (existingEntry.codes.includes(code)) {
        existingEntry.codes = existingEntry.codes.filter(c => c !== code);
      } else {
        const mergedCodes = new Set([...existingEntry.codes, code]);
        existingEntry.codes = Array.from(mergedCodes);
      }
    } else {
      this.crossFilter.geoFilterEntries.push({ type, geoType, codes: [code] });
    }
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.GEO_FILTER);
  }

  public restorePreviousCrossFilter() {
    const restroredCrossFilter = cloneDeep(this.previousCrossFilter);
    this.previousCrossFilter = cloneDeep(this.crossFilter);
    this.crossFilter = restroredCrossFilter;
    this.notifyCrossFilterOptionsChanges();
    this.trace(this.crossFilter, CrossFilterActionType.RESTORE_LAST);
  }

  public isCrossFiltering() {
    return !isEqual(this.crossFilter, this.EMPTY_CROSS_FILTER_OPTIONS);
  }

  private trace(crossFilter: CrossFilterOptions, type: CrossFilterActionType) {
    if (this.ready) {
      this.appMonitorService.recordEvent(CustomEventsConstants.APPLY_CROSS_FILTER, { crossFilter, type });
    }
  }

  private notifyCrossFilterOptionsChanges() {
    const changes: CrossFilterOptionsChange[] = [];
    (Object.keys(this.crossFilter) as (keyof CrossFilterOptions)[]).forEach(key => {
      if (key === 'geoFilterEntries') {
        const addedOrChangedEntries = this.crossFilter.geoFilterEntries.filter(newEntry => {
          const prevEntry = this.previousCrossFilter.geoFilterEntries.find(entry => entry.type === newEntry.type && entry.geoType === newEntry.geoType);
          return (!prevEntry || !isEqual(prevEntry.codes, newEntry.codes));
        });
        const removedEntries = this.previousCrossFilter.geoFilterEntries.filter(prevEntry => {
          const newEntry = this.crossFilter.geoFilterEntries.find(entry => entry.type === prevEntry.type && entry.geoType === prevEntry.geoType);
          return !newEntry;
        });
        for (const changedEntry of [...addedOrChangedEntries, ...removedEntries]) {
          const type = changedEntry.type.toLowerCase() as Lowercase<CrossFilterGeoEntryType>;
          const geoType = changedEntry.geoType.toLowerCase() as Lowercase<CrossFilterGeoType>;
          changes.push(`${type}_${geoType}`);
        }
      } else {
        if (!isEqual(this.previousCrossFilter[key], this.crossFilter[key])) {
          changes.push(key);
        }
      }
    });

    this.filterOptionsChangedSource.next(changes);
  }
}
