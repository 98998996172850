<div class="residence-filter-entry" [ngClass]="residenceFilterEntry.type.toLowerCase()">
  <div class="filter-form-fields">
    <div class="small">{{'RESIDENCE_FILTER.LABEL_FILTER_1' | translate}}</div>
    <mat-form-field class="stretch">
      <mat-select [(ngModel)]="residenceFilterEntry.type" panelWidth="">
        @for(type of types; track $index) {
        <mat-option data-cy="residence-filter-type-value" [value]="type">{{'RESIDENCE_FILTER.' + type |
          translate}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="small">{{'RESIDENCE_FILTER.LABEL_FILTER_2' | translate}}</div>
    <mat-form-field>
      <mat-select [(ngModel)]="residenceFilterEntry.geoType" panelWidth=""
        (selectionChange)="onResidenceGeoTypeChanged()">
        @for(geoType of geoTypes; track $index) {
        <mat-option [value]="geoType">{{'RESIDENCE_FILTER.' + geoType |
          translate}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    @if (residenceFilterEntry.geoType === 'MUNICIPALITY') {
    <mat-form-field class="stretch">
      <mat-select data-cy="area-filters" [(ngModel)]="residenceFilterEntry.codes" multiple="true" panelWidth=""
        [placeholder]="'GEO_FILTER.SELECT_AREA_PLACEHOLDER' | translate">
        <mat-option>
          <ngx-mat-select-search data-cy="type-area" [placeholderLabel]="'ANALYSIS_INPUT.FILTER_TYPE_TEXT' | translate"
            [noEntriesFoundLabel]="'ANALYSIS_INPUT.FILTER_NOT_FOUND' | translate" ngModel
            (ngModelChange)="filterMunicipalities($event)"></ngx-mat-select-search>
        </mat-option>
        @for(municipality of filteredMunicipalities | async; track municipality.code) {
        <mat-option data-cy="municipality-filtered" [value]="municipality.code">{{municipality.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    }

    @else if (residenceFilterEntry.geoType === 'PROVINCE') {
    <mat-form-field class="stretch">
      <mat-select data-cy="area-filters" [(ngModel)]="residenceFilterEntry.codes" multiple="true" panelWidth=""
        [placeholder]="'GEO_FILTER.SELECT_AREA_PLACEHOLDER' | translate">
        <mat-option>
          <ngx-mat-select-search data-cy="type-area" [placeholderLabel]="'ANALYSIS_INPUT.FILTER_TYPE_TEXT' | translate"
            [noEntriesFoundLabel]="'ANALYSIS_INPUT.FILTER_NOT_FOUND' | translate" ngModel
            (ngModelChange)="filterProvinces($event)"></ngx-mat-select-search>
        </mat-option>
        @for(province of filteredProvinces | async; track province.code) {
        <mat-option data-cy="provinces-filtered" [value]="province.code">{{province.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    }
  </div>
  <div class="filter-icon-buttons small">
    <button mat-icon-button (click)="onDeleteClick(residenceFilterEntry)" data-cy=" delete-residence-filter-entry">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
