<div class="container" [style.height.px]="HEIGHT_PX">
  <div data-cy="journey-count-graph" #chart class="chart"></div>
  <div class="spinner" *ngIf="spinner.state$ | async"><ngx-skeleton-loader [count]="LOADER_COUNT"></ngx-skeleton-loader>
  </div>
  <div class="chart-style-toggle" *ngIf="(spinner.state$ | async) === false">
    <mat-button-toggle-group [(ngModel)]="stacked" (ngModelChange)="refreshChart()"
      [hideSingleSelectionIndicator]="true">
      <mat-button-toggle [value]="true"
        matTooltip="{{ 'ANALYSIS_OVERVIEW.PANEL.TREND_GROUPED_MODES.STACKED' | translate }}">
        <mat-icon>area_chart</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle [value]="false"
        matTooltip="{{ 'ANALYSIS_OVERVIEW.PANEL.TREND_GROUPED_MODES.NOT_STACKED' | translate }}">
        <mat-icon>ssid_chart</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
