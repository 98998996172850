export class LocalStorageConstants {

  private static readonly PREFIX = 'nvp';

  public static readonly LANGUAGE = `${LocalStorageConstants.PREFIX}language`;

  public static readonly FEATURE_FLAG = `${LocalStorageConstants.PREFIX}-feature-flag`;

  public static readonly POST_AUTH_REDIRECT = `${LocalStorageConstants.PREFIX}post-auth-redirect`;

  public static readonly IS_DISCLAIMER_ACCEPTED = `${LocalStorageConstants.PREFIX}-accepted-disclaimer`;

  public static readonly APP_MONITORING_DEBUG_MODE = `${LocalStorageConstants.PREFIX}-analyzer-app-monitoring-debug-mode`;

  public static readonly ORDERED_CHARTS_STATE = `${LocalStorageConstants.PREFIX}-charts-state`;
  public static readonly CHARTS_ORDER_DEPRECATED = `${LocalStorageConstants.PREFIX}-charts-order`;
  public static readonly CHARTS_EXPANDED_DEPRECATED = `${LocalStorageConstants.PREFIX}-charts-expanded`;

  public static readonly LAYER_STYLE = `${LocalStorageConstants.PREFIX}-layer-style`;
}
