import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RESIDENCE_GEO_TYPES, ResidenceFilterEntry } from '@shared/resources/analysis/residencefilter/residence-filter-entry';
import { Municipality } from '@shared/resources/geography/municipality';
import { Province } from '@shared/resources/geography/province';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'app-residence-filter-entry',
  templateUrl: './residence-filter-entry.component.html',
  styleUrl: './residence-filter-entry.component.scss'
})
export class ResidenceFilterEntryComponent {

  @Input({ required: true }) public residenceFilterEntry: ResidenceFilterEntry;

  @Output() public deleteEntry: EventEmitter<ResidenceFilterEntry> = new EventEmitter();

  public readonly types: ResidenceFilterEntry['type'][] = ['PLACE_OF_RESIDENCE'];

  public geoTypes = RESIDENCE_GEO_TYPES;
  private _municipalities: Municipality[] = [];
  private _provinces: Province[] = [];

  @Input({ required: true }) set municipalities(municipalities: Municipality[]) {
    this._municipalities = municipalities;
    this.filteredMunicipalities.next(municipalities);
  }

  get municipalities(): Municipality[] {
    return this._municipalities;
  }

  @Input({ required: true }) set provinces(provinces: Province[]) {
    this._provinces = provinces;
    this.filteredProvinces.next(provinces);
  }

  get provinces(): Province[] {
    return this._provinces;
  }

  public filteredMunicipalities = new BehaviorSubject<Municipality[]>([]);
  public filteredProvinces = new BehaviorSubject<Province[]>([]);

  public filterMunicipalities(searchText: string) {
    if (!this.municipalities) {
      return;
    }
    if (!searchText) {
      this.filteredMunicipalities.next([...this.municipalities]);
      return;
    }

    this.filteredMunicipalities.next(
      this.municipalities.filter(
        (municipality) =>
          municipality.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          municipality.code.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }

  public filterProvinces(searchText: string) {
    if (!this.provinces) {
      return;
    }
    if (!searchText) {
      this.filteredProvinces.next([...this.provinces]);
      return;
    }

    this.filteredProvinces.next(
      this.provinces.filter(
        (province) =>
          province.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
          province.code.toString().indexOf(searchText.toLocaleLowerCase()) > -1,
      ),
    );
  }

  public onDeleteClick(residenceFilterEntryToDelete: ResidenceFilterEntry) {
    this.deleteEntry.emit(residenceFilterEntryToDelete);
  }

  public onResidenceGeoTypeChanged() {
    this.residenceFilterEntry.codes = [];
  }
}
