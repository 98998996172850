import { PolygonLevel } from '../geography/polygon-level';
import { GeoFilterEntryType, PredefinedGeoType } from './geofilter/geo-filter-entry';
import { OriginOrDestination } from './origin-or-destination';

export type CrossFilterGeoEntryType = Extract<GeoFilterEntryType, 'ORIGIN' | 'DESTINATION'>;
export type CrossFilterGeoType = Extract<PredefinedGeoType, 'MUNICIPALITY' | 'DISTRICT' | 'NEIGHBORHOOD' | 'PC4'>;

export interface CrossFilterGeoEntry {
  type: CrossFilterGeoEntryType;
  geoType: CrossFilterGeoType;
  codes: string[];
}

export const CROSS_FILTER_GEO_TYPE_TO_POLYGON_LEVEL: Record<CrossFilterGeoType, Exclude<PolygonLevel, 'custom_regions'>> = {
  MUNICIPALITY: 'gemeente',
  DISTRICT: 'wijk',
  NEIGHBORHOOD: 'buurt',
  PC4: 'pc4'
} as const;

export const CROSS_FILTER_GEO_ENTRY_TYPE_TO_ORIGIN_OR_DESTINATION: Record<CrossFilterGeoEntryType, OriginOrDestination> = {
  ORIGIN: 'origin',
  DESTINATION: 'destination'
} as const;
