export class CustomEventsConstants {
  public static readonly DELETE_ANALYSIS = 'delete_analysis';
  public static readonly DELETE_SELF_ANALYSIS = 'delete_self_analysis';
  public static readonly RUN_ANALYSIS = 'run_analysis';
  public static readonly ANALYSIS_RESULT_FAILED_PRIVACY = 'analysis_result_failed_privacy';
  public static readonly OPEN_ANALYSIS = 'open_analysis';
  public static readonly CHANGE_LAYER = 'change_layer';
  public static readonly APPLY_CROSS_FILTER = 'apply_cross_filter';
  public static readonly BANDWIDTHS_SIZE_CHANGED = 'bandwidths_size_changed';
  public static readonly TRANSPARENCY_CHANGED = 'transparency_changed';
  public static readonly LABEL_VISIBILITY_CHANGED = 'label_visibility_changed';
}

export enum CrossFilterActionType {
  RESET = 'reset',
  RESTORE_LAST = 'restore_last',
  MODE = 'mode',
  HOUSEHOLD = 'household',
  URBAN_DENSITY = 'urban_density',
  OBJECTIVE = 'objective',
  GEO_FILTER = 'geo_filter'
}
