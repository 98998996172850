import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { cloneDeep } from 'lodash';
import { ChartState, DEFAULT_CHART_STATES } from '../analysis-diagram-bar/chart-state';

export interface ManageChartsDialogData {
  chartStates: ChartState[];
}

export type ManageChartsDialogResult = ChartState[] | null;

@Component({
  selector: 'app-manage-charts-dialog',
  templateUrl: './manage-charts-dialog.component.html',
  styleUrl: './manage-charts-dialog.component.scss'
})
export class ManageChartsDialogComponent {

  public chartStates: ChartState[];

  public static show(dialog: MatDialog, chartStates: ChartState[]) {
    const dialogConfig: MatDialogConfig<ManageChartsDialogData> = {
      disableClose: true,
      autoFocus: false,
      width: '750px',
      data: { chartStates }
    };
    return dialog.open<ManageChartsDialogComponent, ManageChartsDialogData, ManageChartsDialogResult>(ManageChartsDialogComponent, dialogConfig);
  }

  constructor(private dialogRef: MatDialogRef<ManageChartsDialogComponent, ManageChartsDialogResult>,
    @Inject(MAT_DIALOG_DATA) private data: ManageChartsDialogData
  ) {
    this.chartStates = cloneDeep(data.chartStates);
  }

  public onSelectionChange(event: MatSelectionListChange) {
    event.options.forEach(option => option.value.visible = option.selected);
  }

  public onChartDropped(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.chartStates, event.previousIndex, event.currentIndex);
    }
  }

  public onReset() {
    this.chartStates = cloneDeep(DEFAULT_CHART_STATES);
  }

  public onSave() {
    this.dialogRef.close(this.chartStates);
  }

  public onCancel() {
    this.dialogRef.close(null);
  }
}
