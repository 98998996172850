import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { exhaustiveCheck } from '@shared/utils/exhaustive-check';
import { map, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

export const authGuardFn: CanActivateFn = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isAuthenticatedAndAuthorized()
    .pipe(
      tap(authState => {
        if (authState !== 'OK') {
          console.debug(`Auth state is ${authState}`);
        }
      }),
      map(authState => {
        switch (authState) {
          case 'OK':
            return true;
          case 'EXPIRED':
          case 'NO_REGISTRATION':
            return router.createUrlTree(['authorization/no-license']);
          case 'UNAUTHENTICATED':
            authService.login();
            return false;
          case 'UNKNOWN_ERROR':
            return false;
          default:
            throw exhaustiveCheck(authState);
        }
      })
    );
};
