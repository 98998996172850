<h2 mat-dialog-title>{{ 'ANALYSIS_LIST.SHARE_ANALYSIS.TITLE' | translate }}</h2>

<div mat-dialog-content class="mat-typography">
  <div class="description">{{ 'ANALYSIS_LIST.SHARE_ANALYSIS.DESCRIPTION' | translate}}</div>

  <div class="users-with-access">
    <div class="row users-with-access-title">{{ 'ANALYSIS_LIST.SHARE_ANALYSIS.USERS_WITH_ACCESS' | translate }}</div>

    @for (user of users; track user.fusionAuthUuid) {
    @if (user.fusionAuthUuid !== currentUserFusionAuthUuid) {
    <div class="row">
      <div class="stretch">{{ user.email }}</div>
      <button mat-icon-button class="delete-users-button" (click)="deleteUser(user)"
        [disabled]="spinner.state$ | async">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
    }
    }

    <div class="row">
      <mat-form-field class="stretch">
        <input matInput [(ngModel)]="newUserEmail"
          placeholder="{{ 'ANALYSIS_LIST.SHARE_ANALYSIS.EMAIL_ADDRESS_TOOLTIP' | translate }}"
          (keyup.enter)="addUser()">
      </mat-form-field>
      <button mat-icon-button [disabled]="(spinner.state$ | async) || !newUserEmail.includes('@')" (click)="addUser()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <mat-spinner [diameter]="24" *ngIf="spinner.state$ | async"></mat-spinner>
  <button mat-button (click)="onClose()" color="primary" [disabled]="spinner.state$ | async">{{'BUTTON.CLOSE' |
    translate}}</button>
</div>
