<div class="container" #export>
  <div class="description">
    {{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.RELIABILITY_DESCRIPTION' | translate: { persons: crossfilteredPersons } }}
  </div>
  <div class="fields">
  @for (statisticsField of statisticsFields; track statisticsField) {
    <div class="field">
      <mat-icon class="classifier-icon" [ngClass]="statisticsField.confidenceLevel.toLowerCase()">{{ getConfidenceClassifier(statisticsField.confidenceLevel) }}</mat-icon>
      <div class="data">
        <span class="subject">
          {{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.RELIABILITY_PANEL.' + statisticsField.subject | translate }}
          <mat-icon matTooltip="{{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.RELIABILITY_PANEL.' + statisticsField.subject + '_TOOLTIP' | translate }}" aria-label="Information">info</mat-icon>
        </span>
        <span class="value">&plusmn; {{ statisticsField.marginOfErrorPercentage | number:'1.1-1' }}%</span>
      </div>
    </div>
  }
  </div>
  <div class="spinner" *ngIf="spinner.state$ | async">
    <ngx-skeleton-loader [count]="LOADER_COUNT"></ngx-skeleton-loader>
  </div>
</div>
