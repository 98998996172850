import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/utils/constants/constants';

export class BaseHttpService {
  constructor(protected httpClient: HttpClient) { }

  protected get<R>(url: string): Observable<R> {
    return this.httpClient.get<R>(this.expandUrl(url));
  }

  protected post<R, P = any>(url: string, body: P): Observable<R> {
    return this.httpClient.post<R>(this.expandUrl(url), body);
  }

  protected put<R, P = any>(url: string, body: P): Observable<R> {
    return this.httpClient.put<R>(this.expandUrl(url), body);
  }

  protected delete<R = void>(url: string): Observable<R> {
    return this.httpClient.delete<R>(this.expandUrl(url));
  }

  private expandUrl(url: string): string {
    if (
      url.length > 0 &&
      (url.lastIndexOf('http:', 0) === 0 || url.lastIndexOf('https:', 0) === 0)
    ) {
      return url;
    } else {
      return `${Constants.API_PREFIX}/${url}`;
    }
  }
}
