import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Analysis } from '@shared/resources/analysis/analysis';
import { AnalysisListItem } from '@shared/resources/analysis/analysis-list-item';
import { CreateAnalysis } from '@shared/resources/analysis/create-analysis';
import { CrossFilterOptions } from '@shared/resources/analysis/cross-filter-options';
import { DistanceHistogramResponse } from '@shared/resources/analysis/distance-histogram-response';
import { GetAnalysisCountsResponse } from '@shared/resources/analysis/get-analysis-counts-response';
import { HouseholdResponse } from '@shared/resources/analysis/household-response';
import { KpiOverviewResponse } from '@shared/resources/analysis/kpi-overview-response';
import { ModalSplitResponse } from '@shared/resources/analysis/modal-split-response';
import { ModeTransitionsResponse } from '@shared/resources/analysis/mode-chains-response';
import { ObjectivesResponse } from '@shared/resources/analysis/objectives-response';
import { OdFlowsResponse } from '@shared/resources/analysis/od-flows-response';
import { OriginOrDestination } from '@shared/resources/analysis/origin-or-destination';
import { PersonAttributeResponse } from '@shared/resources/analysis/person-attribute-response';
import { TransportModeGroupType } from '@shared/resources/analysis/transport-mode-group-type';
import { TrendPerModeGroupResponse } from '@shared/resources/analysis/trend-per-mode-group-response';
import { UrbanisationResponse } from '@shared/resources/analysis/urbanisation-response';
import { PolygonLevel } from '@shared/resources/geography/polygon-level';
import { BaseHttpService } from './base-http.service';

export type BandwidthsFeatureProperties = { journeys: number; persons: number; };
export type BandwidthsFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.LineString, BandwidthsFeatureProperties>;
export type PolygonsFeatureProperties = { journeys: number; persons: number; code: string; };
export type PolygonsFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon, PolygonsFeatureProperties>;

@Injectable({
  providedIn: 'root'
})
export class AnalysisHttpService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public createAnalysis(createAnalysis: CreateAnalysis) {
    return this.post<Analysis, CreateAnalysis>(`analysis`, createAnalysis);
  }

  public updateAnalysis(analysisId: number, analysis: CreateAnalysis) {
    return this.put<Analysis>(`analysis/${analysisId}`, analysis);
  }

  public getAnalysisList() {
    return this.get<AnalysisListItem[]>(`analysislist`);
  }

  public getAnalysisById(analysisId: number) {
    return this.get<Analysis>(`analysis/${analysisId}`);
  }

  public deleteAnalysis(analysisId: number) {
    return this.delete(`analysis/${analysisId}`);
  }

  public getAnalysisCounts(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<GetAnalysisCountsResponse, CrossFilterOptions>(`analysis/${analysisId}/counts`, crossFilterOptions);
  }

  public getBandwidthAnalysisGeoJson(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<BandwidthsFeatureCollection, CrossFilterOptions>(`analysis/${analysisId}/layers/bandwidths`, crossFilterOptions);
  }

  public getPolygonAnalysisGeoJson(analysisId: number, crossFilterOptions: CrossFilterOptions, type: OriginOrDestination, level: PolygonLevel) {
    return this.post<PolygonsFeatureCollection, CrossFilterOptions>(`analysis/${analysisId}/layers/areas?type=${type}&level=${level}`, crossFilterOptions);
  }

  public getModalSplitDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<ModalSplitResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/modalsplit`, crossFilterOptions);
  }

  public getModeGroupTrend(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<TrendPerModeGroupResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/modegrouptrend`, crossFilterOptions);
  }

  public getOdFlowsDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<OdFlowsResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/odflows`, crossFilterOptions);
  }

  public getOdMatrix(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<OdFlowsResponse, CrossFilterOptions>(`analysis/${analysisId}/odmatrix`, crossFilterOptions);
  }

  public getHouseholdsDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<HouseholdResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/households`, crossFilterOptions);
  }

  public getUrbanisationDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<UrbanisationResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/urbanisation`, crossFilterOptions);
  }

  public getObjectivesDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<ObjectivesResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/objectives`, crossFilterOptions);
  }

  public getDistanceHistogramDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions, modality: TransportModeGroupType) {
    return this.post<DistanceHistogramResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/distancehistogram?modalitygroup=${modality}`, crossFilterOptions);
  }

  public getModeTransitionsDiagram(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<ModeTransitionsResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/modetransitions`, crossFilterOptions);
  }

  public getKpiValues(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<KpiOverviewResponse, CrossFilterOptions>(`analysis/${analysisId}/diagrams/kpis`, crossFilterOptions);
  }

  public postAnalysisExportPersonAttributes(analysisId: number, crossFilterOptions: CrossFilterOptions) {
    return this.post<PersonAttributeResponse, CrossFilterOptions>(`analysis/${analysisId}/personattributes`, crossFilterOptions);
  }
}
