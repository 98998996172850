<app-main-page-layout [pageLabel]="('GLOBAL.NVP_ANALYZER' | translate) + getAnalysisTitle()" [footer]="true">

  <div class="container">
    <div class="sidebar mat-elevation-z12">

      <div class="header">
        <div class="explain">
          <h2>{{ (mode === 'NEW' ? 'ANALYSIS_INPUT.TITLE_NEW_ANALYSIS' : 'ANALYSIS_INPUT.TITLE_EDIT_ANALYSIS') |
            translate }}
          </h2>
          <div>
            <span>{{ 'ANALYSIS_INPUT.TEXT_INFO_ANALYSIS' | translate }}</span>
            <span>{{ 'ANALYSIS_INPUT.YOUTUBE_PREFIX' | translate }}</span>
            <a href="https://www.youtube.com/playlist?list=PL_Pf09SUGCV4foKeWlwGHqqyUwcadcnz6" target="_blank"
              class="youtube-link">
              <img alt="YouTube icon" src="/assets/youtube.png">
              <span>{{ 'ANALYSIS_INPUT.YOUTUBE_TITLE' | translate }}</span>
            </a>
            <span>{{ 'ANALYSIS_INPUT.YOUTUBE_SUFFIX' | translate }}</span>
          </div>
          @if (editingAnalysis && editingAnalysis.state !== 'CREATING') {
          <div class="editing-analysis-box">
            @if (editingAnalysis.state === 'INVALID') {
            <span>{{ 'ANALYSIS_INPUT.TEXT_PREVIOUS_ANALYSIS_INVALID' | translate }}</span>
            } @else {
            <a href="#" [routerLink]="['/analysis', editingAnalysis.analysisId]">{{
              'ANALYSIS_INPUT.TEXT_PREVIOUS_ANALYSIS_VALID_1' | translate }}</a>
            <span>&nbsp;{{ 'ANALYSIS_INPUT.TEXT_PREVIOUS_ANALYSIS_VALID_2' | translate:{
              journeys: editingAnalysis.journeys | number,
              persons: editingAnalysis.persons | number} }}</span>
            }
          </div>
          }
        </div>
      </div>

      <div class="input-dropdown-list">

        <mat-divider></mat-divider>
        <mat-accordion displayMode="flat" multi="true">
          <mat-expansion-panel data-cy="analysis-metadata" [expanded]="true">
            <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="64px">
              <mat-panel-title>
                {{ 'ANALYSIS_INPUT.BASIC_INFO' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-analysis-metadata [analysis]="analysis" (metadataFormIsValid)="metadataFormStatusChanged($event)">
            </app-analysis-metadata>
          </mat-expansion-panel>
          <mat-expansion-panel data-cy="filter-geo" #expansionPanelGeoFilter>
            <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="64px">
              <mat-panel-title>
                {{ 'ANALYSIS_INPUT.FILTER_GEO' | translate }}
                @if (!analysis.analysisFilterOptions.personFilter.personsFromAnalysisId) {
                <span class="required-filter">*</span>
                }
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-expansion-panel class="extra-explain">
              <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="64px">
                <mat-panel-title innerHTML="{{ 'ANALYSIS_INPUT.TEXT_FILTER_GEO_SHORT' | translate }}">
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p innerHTML="{{ 'ANALYSIS_INPUT.TEXT_FILTER_GEO_PARAGRAPH_1' | translate }}"></p>
              <p innerHTML="{{ 'ANALYSIS_INPUT.TEXT_FILTER_GEO_PARAGRAPH_2' | translate }}"></p>
              <p innerHTML="{{ 'ANALYSIS_INPUT.TEXT_FILTER_GEO_PARAGRAPH_3' | translate }}"></p>
            </mat-expansion-panel>

            <app-geo-filter [geoFilter]="analysis.analysisFilterOptions.geoFilter"
              [geoFilterDrawingPolygon]="geoFilterDrawingPolygon" [municipalities]="municipalities"
              [customRegions]="customRegions" (filterTypeChanged)="onFilterTypeChanged($event)" [provinces]="provinces"
              (geoTypeChanged)="onGeoTypeChanged($event)" (deletedEntry)="onGeoFilterDeleted($event)"></app-geo-filter>
          </mat-expansion-panel>

          <mat-expansion-panel data-cy="filter-time" #expansionPanelTimeFilter>
            <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="64px">
              <mat-panel-title>
                {{ 'ANALYSIS_INPUT.FILTER_TIME' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="explain-inside">
              <span>{{ 'ANALYSIS_INPUT.TEXT_FILTER_TIME' | translate }}</span>
              @if (isTrialUser) {
              <span class="explain-trial">{{ 'ANALYSIS_INPUT.TEXT_FILTER_TIME_TRIAL' | translate }}</span>
              }
            </p>
            <app-date-time-filter
              [dateTimeFilter]="analysis.analysisFilterOptions.dateTimeFilter"></app-date-time-filter>
          </mat-expansion-panel>

          <mat-expansion-panel data-cy="filter-movement" #expansionPanelTransportFilter>
            <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="64px">
              <mat-panel-title>
                {{ 'ANALYSIS_INPUT.FILTER_MOVEMENTS' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="explain-inside" innerHTML="{{ 'ANALYSIS_INPUT.TEXT_FILTER_MOVEMENTS' | translate }}"></p>
            <app-transport-filter
              [transportFilter]="analysis.analysisFilterOptions.transportFilter"></app-transport-filter>
          </mat-expansion-panel>

          <mat-expansion-panel data-cy="filter-persons" #expansionPanelPersonFilter>
            <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="64px">
              <mat-panel-title>
                {{ 'ANALYSIS_INPUT.FILTER_PERSONS' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="explain-inside" innerHTML="{{ 'ANALYSIS_INPUT.TEXT_FILTER_PERSONS' | translate }}"></p>
            <app-person-filter [personFilter]="analysis.analysisFilterOptions.personFilter"
              [municipalities]="municipalities" [provinces]="provinces"
              [analysisListItems]="availableAnalysesForPanelUsage"></app-person-filter>
          </mat-expansion-panel>
        </mat-accordion>

        @if (false) {
        <div class="summary">
          <h2>{{ 'ANALYSIS_INPUT.TITLE_SUMMARY' | translate }}</h2>
          <p>{{ 'ANALYSIS_INPUT.TEXT_SUMMARY' | translate }}</p>
        </div>
        }

      </div>

      <mat-divider></mat-divider>

      <div class="run-query">
        <button data-cy="run-query" mat-stroked-button class="primary-button" color="primary" (click)="saveAnalysis()"
          [disabled]="!!geoFilterDrawingPolygon || running">
          {{ 'ANALYSIS_INPUT.RUN_QUERY' | translate }}
        </button>
      </div>
    </div>

    <div #map class="map">
      @if (geoFilterDrawingPolygon) {
      <div class="mat-typography overlay-panel mat-elevation-z4 floating-panel">
        <mat-card class="add-feature-panel">
          <div class="panel-intro">{{ 'ANALYSIS_INPUT.ADD_POLYGON_WINDOW.INTRO_TEXT' | translate }}</div>

          <div class="upload-file-section">
            <div class="upload-box" (dragover)="onUploadBoxDragOver($event)" (dragleave)="onUploadBoxDragLeave($event)"
              (drop)="onUploadBoxDrop($event)" [class.drag-over]="isDragOver">
              <div class="drag-text">{{ 'ANALYSIS_INPUT.ADD_POLYGON_WINDOW.UPLOAD_BOX.DRAG_FILE' | translate }}</div>
              <div class="uploaded-file-name" *ngIf="uploadedGeoJsonFileName">{{ uploadedGeoJsonFileName }}</div>
              <button class="select-file-button" mat-flat-button color="accent" (click)="onSelectGeoJsonFile()">
                {{ 'ANALYSIS_INPUT.ADD_POLYGON_WINDOW.UPLOAD_BOX.SELECT_FILE' | translate }}
              </button>
              <input type="file" id="geoJsonInput" hidden (change)="onGeoJsonFileSelected($event)" />
            </div>
          </div>

          <div class="edit-buttons">
            <button mat-button color="primary" (click)="saveGeometry()"
              [disabled]="!isPolygonFeatureValid(this.polygonFeature)">{{ 'BUTTON.SAVE' | translate }}
            </button>
            <button mat-button (click)="cancelPolygonDrawing()">{{ 'BUTTON.CANCEL' | translate }}</button>
          </div>
        </mat-card>
      </div>
      }
    </div>
  </div>

  <app-page-spinner [message]="running ? 'ANALYSIS_INPUT.RUNNING_ANALYSIS' : 'GLOBAL.BUSY'"></app-page-spinner>
</app-main-page-layout>
