<h2 mat-dialog-title>{{ 'ANALYSIS_OVERVIEW.DIAGRAMS.MANAGE.DIALOG_TITLE' | translate }}</h2>

<div mat-dialog-content class="mat-typography">
  <mat-selection-list (selectionChange)="onSelectionChange($event)" cdkDropList id="ChartDialogDropList"
    cdkDropListAutoScrollStep="10" (cdkDropListDropped)="onChartDropped($event)">
    @for (chartState of chartStates; track chartState) {
    <mat-list-option [selected]="chartState.visible" [value]="chartState" [class.selected]="chartState.visible" cdkDrag
      cdkDragBoundary="#ChartDialogDropList" cdkDragLockAxis="y">
      <div *cdkDragPreview></div>
      <div class="option-content">
        <div cdkDragHandle class="option-drag-handle"><mat-icon>drag_indicator</mat-icon></div>
        <img [src]="'/assets/charttype/' + chartState.type + '.png'" alt="Thumbnail {{chartState.type}}"
          class="option-thumb" />
        <div class="option-texts">
          <div class="option-title">{{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chartState.type | translate }}</div>
          <div class="option-subtitle">{{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chartState.type + '_TOOLTIP' | translate }}
          </div>
        </div>
      </div>
    </mat-list-option>
    }
  </mat-selection-list>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onReset()">{{'BUTTON.RESET' | translate}}</button>
  <div class="spacer"></div>
  <button mat-button (click)="onCancel()">{{'BUTTON.CANCEL' | translate}}</button>
  <button mat-button (click)="onSave()" color="primary" cdkFocusInitial>{{'BUTTON.OK' | translate}}</button>
</div>
