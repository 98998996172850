import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { DISTANCE_CLASSES_PER_MODE_GROUP, DistanceClass } from '@shared/resources/analysis/distance-class';
import { DistanceHistogramResponse } from '@shared/resources/analysis/distance-histogram-response';
import { TRANSPORT_MODE_GROUPS_TO_MODES, TransportModeGroupType } from '@shared/resources/analysis/transport-mode-group-type';
import * as echarts from 'echarts';
import { ChartService } from 'src/app/services/chart.service';
import { CrossFilteringService } from 'src/app/services/cross-filtering.service';
import { AnalysisHttpService } from 'src/app/services/http/analysis-http.service';
import { Constants } from 'src/app/utils/constants/constants';
import { GraphStyle } from 'src/app/utils/constants/graph-style';
import { LocalSpinner } from 'src/app/utils/local-spinner';
import { ChartType } from '../analysis-diagram-bar/chart-type';

@Component({
  selector: 'app-distance-histogram-by-modegroup',
  templateUrl: './distance-histogram-by-modegroup.component.html',
  styleUrl: './distance-histogram-by-modegroup.component.scss'
})
export class DistanceHistogramByModegroupComponent implements OnInit {

  public readonly HEIGHT_PX = 200;
  public readonly LOADER_COUNT = Math.floor((this.HEIGHT_PX - 20) / 36);

  @Input({ required: true }) public analysisId: number;
  @Input({ required: true }) public modeGroup: keyof typeof TRANSPORT_MODE_GROUPS_TO_MODES;

  public chartType: ChartType;
  public spinner = new LocalSpinner();

  private echartsInstance: echarts.ECharts;
  private distanceHistogramResponse: DistanceHistogramResponse['counts'] = [];

  @ViewChild('chart', { static: true }) private chart: ElementRef;

  constructor(
    private analysisHttpService: AnalysisHttpService,
    private crossFilteringService: CrossFilteringService,
    private translateService: TranslateService,
    private chartService: ChartService
  ) {
    this.crossFilteringService.filterOptionsChanged.pipe(takeUntilDestroyed()).subscribe(changes => {
      if (changes.every(change => change === 'modes')) {
        this.refreshChart();
      } else {
        this.fetchCarJourneyFrequency();
      }
    });

    this.chartService.exportClicked.pipe(takeUntilDestroyed()).subscribe(chartType => {
      if (this.chartType === chartType) {
        this.chartService.exportChart(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.copyClipboardClicked.pipe(takeUntilDestroyed()).subscribe(chartType => {
      if (this.chartType === chartType) {
        this.chartService.copyChartToClipboard(this.echartsInstance).catch(e => console.error(e));
      }
    });

    this.chartService.exportCsvClicked.pipe(takeUntilDestroyed()).subscribe(chartType => {
      if (this.chartType === chartType) {
        const fileName = this.translateService.instant('ANALYSIS_OVERVIEW.PANEL.TITLE.' + this.chartType);
        const numberFormatPct = new Intl.NumberFormat(this.translateService.currentLang, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        const numberFormatCategory = new Intl.NumberFormat(this.translateService.currentLang, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        const totalJourneys = this.distanceHistogramResponse.reduce((sum, entry) => sum + entry.journeys, 0);
        this.chartService.exportDataToCsv(fileName, this.distanceHistogramResponse.map(obj =>
        ({
          distanceClass: this.getDistanceClassLabel(obj.distanceClass, numberFormatCategory).replace(/\n/, ' '),
          journeys: `${numberFormatPct.format((obj.journeys / totalJourneys) * 100)}%`
        })));
      }
    });
  }

  public ngOnInit() {
    this.fetchCarJourneyFrequency();
    // To set the chart type based on the mode group
    this.setChartType(this.modeGroup);
  }

  private fetchCarJourneyFrequency() {
    const crossFilterOptions = this.crossFilteringService.getCrossFilterOptions();
    this.analysisHttpService.getDistanceHistogramDiagram(this.analysisId, crossFilterOptions, this.modeGroup)
      .pipe(this.spinner.register())
      .subscribe(response => {
        this.distanceHistogramResponse = response.counts;
        this.initChartOnlyOnce();
        this.refreshChart();
      });
  }


  private setChartType(modeGroup: TransportModeGroupType) {
    switch (modeGroup) {
      case 'CAR':
        this.chartType = ChartType.CAR_DISTANCE_HISTOGRAM;
        break;
      case 'BIKE':
        this.chartType = ChartType.BIKE_DISTANCE_HISTOGRAM;
        break;
      case 'FOOT':
        this.chartType = ChartType.FOOT_DISTANCE_HISTOGRAM;
        break;
      case 'PUBLIC_TRANSPORT':
        this.chartType = ChartType.PUBLIC_TRANSPORT_DISTANCE_HISTOGRAM;
    }
  }

  private refreshChart() {
    if (this.echartsInstance) {
      this.echartsInstance.setOption(this.getChartOptions());
    }
  }

  private initChartOnlyOnce() {
    if (!this.echartsInstance) {
      this.echartsInstance = echarts.init(this.chart.nativeElement);
      this.echartsInstance.on('mousemove', () => this.echartsInstance.getZr().setCursorStyle('default'));
    }
  }

  private getChartOptions() {
    const numberFormatPct = new Intl.NumberFormat(this.translateService.currentLang, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const numberFormatCategory = new Intl.NumberFormat(this.translateService.currentLang, { minimumFractionDigits: 0, maximumFractionDigits: 2 });

    return {
      xAxis: {
        type: 'category',
        ...GraphStyle.AXIS_STYLE,
        axisLabel: {
          interval: 0
        },
        data: DISTANCE_CLASSES_PER_MODE_GROUP[this.modeGroup].map(distanceClass => this.getDistanceClassLabel(distanceClass, numberFormatCategory))
      },
      yAxis: {
        type: 'value',
        ...GraphStyle.AXIS_STYLE,
        axisLabel: {
          formatter: '{value}%'
        }
      },
      ...GraphStyle.TEXT_STYLE,
      grid: {
        right: 15,
        top: 15,
        bottom: 30
      },
      ...GraphStyle.TEXT_STYLE,
      series: [
        {
          data: this.getChartData(numberFormatCategory),
          type: 'bar',
          color: Constants.COLORS_PER_MODE[this.modeGroup === 'PUBLIC_TRANSPORT' ? 'TRAIN' : TRANSPORT_MODE_GROUPS_TO_MODES[this.modeGroup][0]],
          barMaxWidth: 50,
          barCategoryGap: '20%',
          label: {
            show: true,
            position: 'top',
            formatter: (params: any) => `${numberFormatPct.format(params.value[1])}%`,
            textStyle: {
              fontSize: 11,
              fontWeight: 'bold'
            }
          }
        }
      ],
    };
  }

  private getChartData(numberFormatCategory: Intl.NumberFormat) {
    const totalJourneys = this.distanceHistogramResponse.reduce((sum, entry) => sum + entry.journeys, 0);
    return this.distanceHistogramResponse.map(entry => {
      const xAxisLabel = this.getDistanceClassLabel(entry.distanceClass, numberFormatCategory);
      return [
        xAxisLabel,
        totalJourneys > 0 ? (entry.journeys / totalJourneys) * 100 : 0
      ];
    });
  }

  private getDistanceClassLabel(distanceClass: DistanceClass, numberFormatCategory: Intl.NumberFormat) {
    let xAxisLabel = '';
    if (distanceClass.maxDistanceMeters !== null) {
      if (distanceClass.minDistanceMeters === 0) {
        xAxisLabel = "< " + numberFormatCategory.format(distanceClass.maxDistanceMeters / 1000) + " km";
      } else {
        xAxisLabel = (numberFormatCategory.format(distanceClass.minDistanceMeters / 1000) +
          " - " +
          numberFormatCategory.format(distanceClass.maxDistanceMeters / 1000) + " km").replace(" km", "\nkm");
      }
    } else {
      xAxisLabel = " > " + (numberFormatCategory.format(distanceClass.minDistanceMeters / 1000)) + " km ";
    }
    return xAxisLabel;
  }
}
