<div class="geo-filters">
  <div class="add-filter">
    @for (geoFilterEntry of geoFilter.entries; track $index) {
    <app-geo-filter-entry [geoFilterEntry]="geoFilterEntry" [municipalities]="municipalities"
      [customRegions]="customRegions" (delete)="onGeoFilterEntryToBeDeleteEvent($event)" [provinces]="provinces"
      [locked]="!!geoFilterDrawingPolygon && geoFilterDrawingPolygon !== geoFilterEntry"
      (geoTypeChanged)="onGeoTypeChanged($event)"
      (filterTypeChanged)="onFilterTypeChanged($event)"></app-geo-filter-entry>
    }
    <button mat-stroked-button data-cy="add-geo-filter" class="add-button" color="primary"
      [disabled]="!!geoFilterDrawingPolygon" (click)="onAddGeoFilterClick()">+ {{ 'GEO_FILTER.BUTTON_ADD_FILTER' |
      translate }}</button>
  </div>
</div>
