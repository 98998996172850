import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDto } from '@shared/resources/user-dto';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AnalysisUsersHttpService } from 'src/app/services/http/analysis-users-http.service';
import { MessageService } from 'src/app/services/message.service';
import { LocalSpinner } from 'src/app/utils/local-spinner';

export type ShareAnalysisDialogResult = void;
export type ShareAnalysisDialogData = { analysisId: number; };

@Component({
  selector: 'app-share-analysis-dialog',
  templateUrl: './share-analysis-dialog.component.html',
  styleUrl: './share-analysis-dialog.component.scss'
})
export class ShareAnalysisDialogComponent {

  public spinner = new LocalSpinner();
  public users: UserDto[] = [];
  public newUserEmail = '';
  public currentUserFusionAuthUuid = '';

  constructor(
    private dialogRef: MatDialogRef<ShareAnalysisDialogComponent, ShareAnalysisDialogResult>,
    @Inject(MAT_DIALOG_DATA) private data: ShareAnalysisDialogData,
    private messageService: MessageService,
    private analysisUsersHttpService: AnalysisUsersHttpService,
    private oidcSecurityService: OidcSecurityService) {

    this.oidcSecurityService.userData$.pipe(takeUntilDestroyed()).subscribe(userData => {
      this.currentUserFusionAuthUuid = userData.userData.sub;
    });

    this.analysisUsersHttpService.getUsers(this.data.analysisId)
      .pipe(this.spinner.register())
      .subscribe(users => {
        this.users = users;
      });
  }

  public onClose() {
    this.dialogRef.close();
  }

  public addUser() {
    const email = this.newUserEmail.trim().toLowerCase();
    if (this.users.find(u => u.email === email)) {
      this.messageService.showErrorSnackBar('ANALYSIS_LIST.SHARE_ANALYSIS.USER_ALREADY_ADDED');
      return;
    }

    this.analysisUsersHttpService.addUser(this.data.analysisId, email)
      .pipe(this.spinner.register())
      .subscribe({
        next: (user) => {
          this.users.push(user);
          this.newUserEmail = '';
          this.messageService.showToaster('ANALYSIS_LIST.SHARE_ANALYSIS.USER_ADDED_SUCCESSFULLY');
        },
        error: (err) => {
          const errorMessage = err.status === 404 ? 'ANALYSIS_LIST.SHARE_ANALYSIS.USER_NOT_FOUND' : 'ANALYSIS_LIST.SHARE_ANALYSIS.USER_ADDED_FAILED';
          this.messageService.showErrorSnackBar(errorMessage);
        }
      });
  }

  public deleteUser(user: UserDto) {
    this.analysisUsersHttpService.deleteUser(this.data.analysisId, user.fusionAuthUuid)
      .pipe(this.spinner.register())
      .subscribe(() => {
        this.users = this.users.filter(u => u.fusionAuthUuid !== user.fusionAuthUuid);
      });
  }
}
