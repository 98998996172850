import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShareProjectParams } from '@shared/resources/analysis/analysis-access/share-projectparams';
import { UserDto } from '@shared/resources/user-dto';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class AnalysisUsersHttpService extends BaseHttpService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getUsers(analysisId: number) {
    return this.get<UserDto[]>(`analysis/${analysisId}/users`);
  }

  public addUser(analysisId: number, email: string) {
    return this.post<UserDto, ShareProjectParams>(`analysis/${analysisId}/users`, { email });
  }

  public deleteUser(analysisId: number, fusionAuthUuid: string) {
    return this.delete(`analysis/${analysisId}/users/${fusionAuthUuid}`);
  }
}
