<app-analysis-counts [analysis]="analysis" (crossFilterValid)="crossFilterValid = $event"></app-analysis-counts>

<app-cross-filter-chips></app-cross-filter-chips>

<mat-divider></mat-divider>

@if (analysis) {
@if (!crossFilterValid) {
<div class="invalid-analysis">
  <mat-icon class="material-icons-outlined">report_problem</mat-icon>
  {{ 'ANALYSIS_INPUT.ERRORS.INVALID_PERSON_COUNT' | translate }}
</div>
<button class="reset-filter-button" mat-stroked-button (click)="revertCrossFilter()">
  {{ 'ANALYSIS_OVERVIEW.REVERT_LAST_CROSS_FILTER' | translate }}
</button>
} @else {
<div class="charts-container" cdkDropList id="ChartBarDropList" cdkDropListAutoScrollStep="16"
  (cdkDropListDropped)="onChartDropped($event)">
  @for (chartState of orderedChartStates; track chartState.type) {
  @if (chartState.visible) {
  <div cdkDrag cdkDragBoundary="#ChartBarDropList" cdkDragLockAxis="y">
    <div class="chart-bar-item-drag-placeholder" *cdkDragPlaceholder></div>
    <div class="mat-typography mat-elevation-z3 chart-bar-item-drag-preview" *cdkDragPreview>
      {{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chartState.type | translate}}
    </div>

    <mat-expansion-panel [(expanded)]="chartState.expanded" (expandedChange)="onChartExpandedChange()"
      class="diagram-panel">

      <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="48px">
        <mat-panel-title>
          <div class="drag-handle" cdkDragHandle>
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <div class="item-name">
            {{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chartState.type | translate }}
            <mat-icon matTooltip="{{ 'ANALYSIS_OVERVIEW.PANEL.TITLE.' + chartState.type + '_TOOLTIP' | translate }}"
              matTooltipClass="wide-tooltip" matTooltipShowDelay="300"
              (click)="$event.stopPropagation()">info</mat-icon>
          </div>
          <div class="icons">
            <button mat-icon-button [matMenuTriggerFor]="exportMenu" (click)="$event.stopPropagation();">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
          <mat-menu #exportMenu="matMenu">
            <button mat-menu-item (click)="exportChart(chartState.type)">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_AS_IMAGE' | translate}}</button>
            <button mat-menu-item (click)="copyChartToClipboard(chartState.type)">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.COPY_TO_CLIPBOARD' | translate}}</button>
            <button mat-menu-item (click)="exportChartToCsv(chartState.type)">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_AS_CSV' | translate}}</button>
            @if (chartState.type === 'ORIGINS_AND_DESTINATIONS') {
            <button mat-menu-item (click)="exportOdMatrix(chartState.type)" [disabled]="exportInProgress">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_OD_MATRIX' | translate}}</button>
            }
            @if (chartState.type === 'KPI') {
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="exportPersonAttributes()" [disabled]="exportingPersonAttributes">{{
              'ANALYSIS_OVERVIEW.DIAGRAMS.EXPORT_MENU.EXPORT_PERSON_ATTRIBUTES_AS_CSV' | translate}}</button>
            }
          </mat-menu>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>
        @switch (chartState.type) {
        @case('RELIABILITY') {
        <app-reliability-statistics [analysisId]="analysis.analysisId"></app-reliability-statistics>
        }
        @case('KPI') {
        <app-kpi-overview [analysisId]="analysis.analysisId"></app-kpi-overview>
        }
        @case ('MOVEMENTS') {
        <app-modal-split-chart [analysisId]="analysis.analysisId"></app-modal-split-chart>
        }
        @case ('ORIGINS_AND_DESTINATIONS') {
        <app-od-flows-chart [analysisId]="analysis.analysisId"></app-od-flows-chart>
        }
        @case('PANELISTS_HOUSEHOLDS'){
        <app-household-chart [analysisId]="analysis.analysisId"></app-household-chart>
        }
        @case('PANELISTS_URBANISATION'){
        <app-urbanisation-chart [analysisId]="analysis.analysisId"></app-urbanisation-chart>
        }
        @case('OBJECTIVES'){
        <app-objectives-chart [analysisId]="analysis.analysisId"></app-objectives-chart>
        }
        @case('MODE_CHAIN'){
        <app-mode-transitions-chart [analysisId]="analysis.analysisId"></app-mode-transitions-chart>
        }
        @case('TREND_GROUPED_MODES'){
        <app-trends-by-modegroup [analysisId]="analysis.analysisId"></app-trends-by-modegroup>
        }
        @case ('CAR_DISTANCE_HISTOGRAM') {
        <app-distance-histogram-by-modegroup [analysisId]="analysis.analysisId"
          modeGroup="CAR"></app-distance-histogram-by-modegroup>
        }
        @case ('BIKE_DISTANCE_HISTOGRAM') {
        <app-distance-histogram-by-modegroup [analysisId]="analysis.analysisId"
          modeGroup="BIKE"></app-distance-histogram-by-modegroup>
        }
        @case ('FOOT_DISTANCE_HISTOGRAM') {
        <app-distance-histogram-by-modegroup [analysisId]="analysis.analysisId"
          modeGroup="FOOT"></app-distance-histogram-by-modegroup>
        }
        @case ('PUBLIC_TRANSPORT_DISTANCE_HISTOGRAM') {
        <app-distance-histogram-by-modegroup [analysisId]="analysis.analysisId"
          modeGroup="PUBLIC_TRANSPORT"></app-distance-histogram-by-modegroup>
        }
        }
      </ng-container>
    </mat-expansion-panel>
  </div>
  }
  }
  <button mat-stroked-button color="primary" class="manage-charts-button" (click)="onManageDiagramsClick()">
    <mat-icon>
      {{ availableChartCount === 0 ? 'insert_chart' : 'add_chart' }}
    </mat-icon>
    &nbsp;{{ (availableChartCount === 0 ? 'ANALYSIS_OVERVIEW.DIAGRAMS.MANAGE.MANAGE_CHARTS' :
    'ANALYSIS_OVERVIEW.DIAGRAMS.MANAGE.ADD_CHARTS') | translate:{availableChartCount} }}
  </button>
</div>
}
}
